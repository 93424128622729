// @flow
import * as React                                                                  from 'react'
import { useEffect, useState }                                                     from 'react'
// import { AuthProviderContext }                                                     from "../providers/AuthProvider"
import { Company }                                                                 from "../models/company"
import firebase                                                                    from "firebase"
import { Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core"
import { EditCompanyField }                                                        from "./company-detail"

type Props = {
    parentId: string,
    id: string
};

export function ContractorDetail({ id, parentId }: Props) {
    // const { auth } = useContext(AuthProviderContext)
    const [ company, setCompany ] = useState<Company>()
    const companyRef = firebase.firestore().doc(`/companies/${ parentId }/contractors/${ id }`)

    const getCompany = React.useMemo(() => async () => {
        const res = await companyRef.get()
        const l_company = res.data() as Company
        l_company.id = res.id
        setCompany(l_company)
    }, [companyRef]);

    useEffect(() => {
        getCompany()
    }, [getCompany])

    if (!company) return <h1> Ładowanie </h1>

    return (
            <Card style={ { padding: "2em", width: "100%", height: 700 } }>
                <Typography variant={ "h3" }> { company.name } </Typography>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Kod rejestracji</TableCell>
                                <EditCompanyField field={ "validation_key" } company={ company }
                                                  companyRef={ companyRef } onSave={ () => getCompany() }/>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
    )
}
