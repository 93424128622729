// @flow
import * as React               from 'react'
import { useEffect, useState }  from 'react'
import { DataGrid, GridColDef } from "@material-ui/data-grid"
import { AppError }             from "../models/appError"
import firebase                 from "firebase"
import { Button, ButtonGroup }  from "@material-ui/core"
import { useHistory }           from "react-router-dom"

type Props = {};

export function AppErrors(_: Props) {
    const history = useHistory()
    const [ errors, setErrors ] = useState<AppError[]>([])
    const [ isLoading, setIsLoading ] = useState(true)


    const columns: GridColDef[] = [
        {
            headerName: "Data", field: "timestamp", flex: 1, renderCell: params => (
                    <p> { new Date(params.row.timestamp.seconds * 1000).toLocaleString("pl", {
                        day   : "2-digit",
                        month : "2-digit",
                        year  : "numeric",
                        hour  : "2-digit",
                        minute: "2-digit"
                    }) }</p>
            )
        },
        { headerName: "Zostało rozwiązane", field: "resolved", flex: 1 },
        {
            headerName: "Akcje", field: "action", flex: 1, renderCell: (params => {
                return <ButtonGroup>
                    <Button onClick={ () => {
                        history.push(`/app-bugs/${ params.row.id }`)
                    }
                    }>Sprawdź</Button>
                    <Button>Oznacz jako sprawdzone</Button>
                    <Button color={ "secondary" }>Usuń</Button>
                </ButtonGroup>
            })
        }
    ]


    const loadErrors = async () => {
        const users_snapshot = await firebase
                .firestore()
                .collection("user-errors")
                .get()


        users_snapshot.forEach((doc) => {
            console.log(doc.data())
            const err = doc.data() as AppError
            err.id = doc.id
            setErrors((current_errors => [ err, ...current_errors ]))
        })

        setIsLoading(false)
    }

    useEffect(() => {
        loadErrors()
    }, [])

    return (
            <div style={ { width: "100%", height: 700 } }>
                <DataGrid disableColumnMenu key={ 1 } loading={ isLoading } rows={ errors } columns={ columns }/>
            </div>
    )
}
