// @flow
import * as React     from 'react'
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

type Props = {};

const useStyles = makeStyles({
    cc: {
        position: "fixed",
        right   : "1em",
        bottom  : "1em"
    }
});

export function Home(_: Props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const classes = useStyles();

    return (
            <div>
                <Typography variant={ "h4" }>
                    Strona zarządzająca systemem urządzeń DRSM
                </Typography>
                <Typography variant={ "body1" }>
                    Na tej stronie możesz zarządzać urządzeniami oraz użytkownikami swojej firmy
                </Typography>

                <Typography variant={ "body1" }>
                    Jeżeli jesteś zalogowany po lewej stronie pojawią akcję do których masz dostęp. W innym przypadku
                    musisz się najpierw zalogować. <br/>
                    W przypadku problemów z aplikacją skontaktuj się z <a
                        href="mailto:bok@hipernet.info">bok@hipernet.info</a>
                </Typography>
            </div>
    )
}

