// @flow
import * as React               from 'react'
import { Route, Switch }        from "react-router-dom"
import { Users }                from "../pages/users"
import { AppErrors }            from "../pages/appErrors"
import { AppErrorDetail }       from "../pages/appErrorDetail"
import { Login }                from "../pages/login"
import { Home }                 from "../pages/home"
import { Zones }                from "../pages/zones"
import { IsLoggedInGuardRoute } from "../guards/isLoggedInGuard"
import { Logout }               from "../pages/logout"
import { Register }             from "../pages/register"
import { Drsm }                 from "../pages/drsm"
import { Companies }            from "../pages/companies"
import { DrsmDetails }          from "../pages/drsm-details"
import { User }                 from "../pages/user"
import { ChangePassword }       from "../pages/changePassword"
import { CompanyDetail }        from "../pages/company-detail"
import { NoPerms }              from "../pages/no-perms"
import { DrsmErrors }           from "../pages/drsm-errors"
import { DrsmTransfer }         from "../pages/drsm-transfer"
import { ReadDrsmLog } from '../pages/readDrsmLog'

type Props = {};

export function RouterComponent(_: Props) {
    return (
            <Switch>
                <IsLoggedInGuardRoute path={ "/users/:id" } component={ User } requiredPerm="manage_perms" />
                <IsLoggedInGuardRoute path={ "/users" } component={ Users } requiredPerm="manage_perms" />
                <IsLoggedInGuardRoute path={ "/app-bugs/:id" } component={ AppErrorDetail } requiredPerm="god" />
                <IsLoggedInGuardRoute path={ "/app-bugs" } component={ AppErrors } requiredPerm="god" />
                <IsLoggedInGuardRoute path={ "/zones" } component={ Zones } requiredPerm="edit_zones" />
                <IsLoggedInGuardRoute path={ "/drsm/:id" } component={ DrsmDetails } requiredPerm="connect_drsm" />
                <IsLoggedInGuardRoute path={ "/drsm" } component={ Drsm } requiredPerm="connect_drsm" />
                <IsLoggedInGuardRoute path={ "/companies/:id" } component={ CompanyDetail } requiredPerm="god" />
                <IsLoggedInGuardRoute path={ "/companies" } component={ Companies }  requiredPerm="god" />
                <IsLoggedInGuardRoute path={ "/change-password" } component={ ChangePassword } requiredPerm="god" />
                <IsLoggedInGuardRoute path={ "/drsm-errors" } component={ DrsmErrors } requiredPerm="god" />
                <IsLoggedInGuardRoute path={ "/drsm-transfers" } component={ DrsmTransfer } requiredPerm="god" />
                <IsLoggedInGuardRoute path={ "/read-drsm-log" } component={ReadDrsmLog} requiredPerm="read_device_log" />

                <Route path={ "/no-perms" } component={ NoPerms }/>
                <Route path={ "/login" } component={ Login }/>
                <Route path={ "/logout" } component={ Logout }/>
                <Route path={ "/register" } component={ Register }/>
                <Route path={ "/" } component={ Home }/>
            </Switch>
    )
}
