import firebase    from "firebase/app"
import { Company } from "../models/company"
import { Zone }    from "../models/zone"

export abstract class Kesz {
    static map: Map<string, any>
    static ref: string


    static async _get<T>(uuid: string): Promise<T | undefined> {
        if (this.map.has(uuid)) {
            return this.map.get(uuid)
        }


        const result = (await firebase.firestore().collection(this.ref).doc(uuid).get()).data() as T
        this.map.set(uuid, result)
        return result
    }

}

export class KompanyKesz extends Kesz {
    static map: Map<string, Company> = new Map<string, Company>()
    static ref = "companies"

    static get(uuid: string): Promise<Company | undefined> {
        return super._get<Company>(uuid)
    }
}

export class ZoneKesz extends Kesz {
    static map: Map<string, Zone> = new Map<string, Zone>()
    static ref = "zones"

    static get(uuid: string): Promise<Zone | undefined> {
        return super._get<Zone>(uuid)
    }
}
