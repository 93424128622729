import React                       from 'react'
import ReactDOM                    from 'react-dom'
import './index.css'
import reportWebVitals             from './reportWebVitals'
import firebase                    from "firebase/app"
import { Skeleton }                from "./components/skeleton"
import { BrowserRouter as Router } from "react-router-dom"
import { CssBaseline, Typography } from "@material-ui/core"
import { AuthProviderComponent }   from "./components/AuthProviderComponent"


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = firebase.initializeApp({
    apiKey           : "AIzaSyAVUt0-9AXfZ_x3I1aftuiDnUIuJe-KaUg",
    authDomain       : "hipernet-drsm-dev.firebaseapp.com",
    projectId        : "hipernet-drsm-dev",
    storageBucket    : "hipernet-drsm-dev.appspot.com",
    messagingSenderId: "1049176775084",
    appId            : "1:1049176775084:web:a53a9686cec03f718a4209",
    measurementId    : "G-6C06RSX5XT"
})

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

ReactDOM.render(
        <React.StrictMode>
            <Router>
                <AuthProviderComponent>
                    <CssBaseline/>
                    <Skeleton/>
                </AuthProviderComponent>
            </Router>
            <Typography variant={ "caption" } style={ {
                position: "fixed",
                right   : "1em",
                bottom  : "1em"
            } }>
                Wszystkie prawa zastrzeżone HIPERNET Sp. z o.o. 2021 &copy;
            </Typography>
        </React.StrictMode>,
        document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
