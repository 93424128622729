// @flow
import * as React                                  from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import { useParams }                               from "react-router-dom"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
}                                                  from "@material-ui/core"
import { makeStyles }                              from "@material-ui/core/styles"
import firebase                                    from "firebase"
import { DrsmDevice }                              from "../models/drsm"
import { Company }                                 from "../models/company"
import { Zone }                                    from "../models/zone"
import KeyboardArrowDownIcon                       from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon                         from '@material-ui/icons/KeyboardArrowUp'
import { AuthProviderContext }                     from "../providers/AuthProvider"
import { ExtUser }                                 from "../models/extUser"
import ExpandMoreIcon                              from '@material-ui/icons/ExpandMore'


type Props = {};

type Params = {
    id: string
}

const useStyles = makeStyles({
    card     : {
        padding: "2em"
    },
    grid     : {
        marginTop: "2em"
    },
    gridItem : {
        padding: "2em",
        height : "40vh"
    },
    frameItem: {
        height: "40vh"
    },
    table    : {
        marginTop: "2em"
    }
})

const enumTranslations = new Map<string, string>([
    [ "LogEnum.RelayOverride", "Manualna zmiana przekaźnika" ],
    [ "LogEnum.OnConnect", "Połączenie instalatora z urządzeniem" ],
    [ "LogEnum.OnDisconnect", "Rozłączenie instalatora z urządzeniem" ],
    [ "LogEnum.OnZoneChange", "Zmiana strefy urządzenia" ],
])

const metaTranslations = new Map<string, string>([
    [ "time", "czas" ],
    [ "latitude", "szerokość geograficzna" ],
    [ "longitude", "długość geograficzna" ],
    [ "state", "stan" ],
    [ "new_zone", "nowa strefa" ],
    [ "last_zone", "stara strefa" ]
])

export function DrsmDetails(props: Props) {
    const { auth } = useContext(AuthProviderContext)
    const classes = useStyles()
    const { id: rawId } = useParams<Params>()
    const id = decodeURI(rawId)
    const lastUser = useRef<ExtUser>()

    const [ device, setDevice ] = useState<DrsmDevice>()
    const [ logs, setLogs ] = useState<any[]>([])

    const hasAddress = (): boolean => {
        return !!device && !!device?.latitude && !!device?.longitude
    }

    const getLastUser = async () => {
        for (const log of logs) {
            if (log.log_id === "LogEnum.OnConnect") {
                lastUser.current = (await log?.user?.get())?.data() as ExtUser
            }
        }
        return null
    }

    const dateFromResponse = (data: firebase.firestore.DocumentData): Date | null => {
        if (data["last_connect_time"])
            return new Date(data["last_connect_time"]["seconds"] * 1000)
        return null
    }

    const downloadDevice = React.useMemo(() => async () => {
        const response = (await firebase.firestore().collection("drsm-device").doc(id).get()).data()
        const dev = response as DrsmDevice

        dev.last_connect_time = dateFromResponse(response!)
        dev.owner_company = (await response!["owner_company"]?.get()).data() as Company
        dev.last_known_zone = (await response!["last_known_zone"]?.get())?.data() as Zone

        setDevice(dev)
    }, [id]);

    const downloadActionLogs = async () => {
        const deviceRef = firebase.firestore().collection("drsm-device").doc(id)
        const response = await firebase.firestore()
                                       .collection("drsm-device-action-log")
                                       .where("drsm", "==", deviceRef)
                                       .orderBy("timestamp", "desc")
                                       .get()

        const items: Record<string, any>[] = []
        response.forEach(item => items.push(item.data()))
        setLogs(items)
        await getLastUser()
    }

    useEffect(() => {
        setLogs([])
        downloadDevice()
    }, [downloadDevice]);

    return (
            <div>
                <Card className={ classes.card } elevation={ 10 }>
                    <Typography variant={ "h4" }> Urządzenie Drsm #{ id } </Typography>

                    <Grid container spacing={ 4 } className={ classes.grid }>
                        <Grid item xs={ 6 }><Typography variant={ "h6" }> Ostatnio znana pozycja </Typography></Grid>
                        <Grid item xs={ 6 }><Typography variant={ "h6" }> Ostatnio znane dane </Typography></Grid>
                        <Grid item xs={ 6 }>
                            <div className={ classes.frameItem }>
                                { hasAddress() ? <iframe style={ { height: "100%", width: "100%" } }
                                                         title="map"
                                                         src={ `https://www.google.com/maps/embed/v1/place?key=AIzaSyCzsH7micyr03TEgl2U_BkeDPaC65LWVpA&q=${ device?.latitude }, ${ device?.longitude }` }/> : "Brak danych lokalizacji" }
                            </div>
                        </Grid>
                        <Grid item xs={ 6 }>
                            <Paper elevation={ 12 } className={ classes.gridItem }>

                                <TableContainer>
                                    <Table size="medium">
                                        <TableBody>
                                            <DevDataRow condition={ !!auth.user?.perms.god } name={ "Siła sygnału" }
                                                        value={ device?.last_rssi }/>
                                            <DevDataRow condition={ !!auth.user?.perms.god }
                                                        name={ "Status połączenia" } value={ device?.last_sync }/>
                                            <DevDataRow condition name={ "Firma zarządzająca" }
                                                        value={ device?.owner_company.name }/>
                                            <DevDataRow condition name={ "Ostatnie połączenie serwisanta" }
                                                        value={ device?.last_connect_time?.toLocaleString("pl") }/>
                                            <DevDataRow condition name={ "Strefa" }
                                                        value={ device?.last_known_zone?.name }/>
                                            <DevDataRow condition name={ "Wersja oprogramowania urządzenia" }
                                                        value={ device?.software_version.split("-")[0] }/>
                                            <DevDataRow condition name={ "Ostatni instalator" }
                                                        value={ lastUser.current?.last_name }/>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Accordion className={ classes.table } onChange={ (_, expanded) => {
                        if (expanded && logs.length === 0) downloadActionLogs()
                    } }>
                        <AccordionSummary expandIcon={ <ExpandMoreIcon/> }> Log działań instalatorów </AccordionSummary>
                        <AccordionDetails>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell> Rodzaj Wydarzenia </TableCell>
                                        <TableCell> Data </TableCell>
                                        { auth.user?.perms.god ? <TableCell> Wersja aplikacji </TableCell> : null }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { logs.map(log => <LogRow key={ Math.random() } log={ log }/>) }
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </div>
    )
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
})


function LogRow({ log }: { log: any }) {
    const { auth } = useContext(AuthProviderContext)
    const [ open, setOpen ] = useState(false)
    const classes = useRowStyles()

    return <React.Fragment>
        <TableRow className={ classes.root }>
            <IconButton aria-label="expand row" size="small" onClick={ () => setOpen(!open) }>
                { open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/> }
            </IconButton>
            <TableCell> { enumTranslations.get(log["log_id"]) ?? log["log_id"] } </TableCell>
            <TableCell> { new Date(
                    log["timestamp"]["seconds"] * 1000).toLocaleString() }
            </TableCell>
            { auth.user?.perms.god ? <TableCell> { log["app_version"] ?? "Wersja nieznana" } </TableCell> : null }
        </TableRow>
        <TableRow>
            <TableCell style={ { paddingBottom: 0, paddingTop: 0 } } colSpan={ 6 }>
                <Collapse in={ open } timeout="auto" unmountOnExit>
                    <Box margin={ 1 }>
                        <Typography variant="h6" gutterBottom component="div">
                            Metadane
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa</TableCell>
                                    <TableCell>Wartość</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <LogMetaView meta={ log["meta"] }/>
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>
}


function LogMetaView({meta}: any) {
    const [ params, setParams ] = useState<{ key: string, value: any }[]>([])

    const loadLogs = React.useMemo(() => async () => {
        for (const key in meta) {

            if (key.includes("zone")) {
                const zone = (await meta[key].get()).data()
                setParams(current => [ { key, value: zone["name"] }, ...current ])
                continue
            }

            setParams(current => [ { key, value: meta[key] }, ...current ])
        }
    }, [meta]);

    useEffect(() => {
        loadLogs().catch(e => console.error(e));
    }, [loadLogs])

    return <>
        { params.map(val => (
                <TableRow key={ val.key }>
                    <TableCell>{ metaTranslations.get(val.key) ?? val.key }</TableCell>
                    <TableCell>{ val.value.toString() }</TableCell>
                </TableRow>
        )) }
    </>
}


function DevDataRow({ value, name, condition }: { value: any, name: string, condition: boolean }) {
    if (!value) return null
    if (!condition) return null

    return <TableRow>
        <TableCell>{ name }</TableCell>
        <TableCell>{ value.toString() }</TableCell>
    </TableRow>
}
