// @flow
import * as React                                                from 'react'
import firebase                                                  from "firebase"
import { Field, Form, Formik }                                   from 'formik'
import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core"
import { makeStyles }                                            from "@material-ui/core/styles"

type Props = {
    open: boolean,
    setOpen: (val: boolean) => void,
    collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
};

const initialValues = {
    name          : "",
    validation_key: ""
}

const useStyles = makeStyles({
    textInput: {},
    button   : {
        marginTop: "1em"
    }
})

export function AddCompanyDialog({ open, setOpen, collection }: Props) {
    const classes = useStyles()

    const create = async (values: typeof initialValues) => {
        await collection.add(values)
        setOpen(false)
    }

    return (
            <Dialog open={ open }>
                <DialogTitle> Dodaj firmę </DialogTitle>
                <DialogContent>
                    <Formik initialValues={ initialValues } onSubmit={ create }>
                        { ({ values }) => (
                                <Form>
                                    <Field fullWidth className={ classes.textInput }
                                           label={ "Nazwa firmy" }
                                           as={ TextField }
                                           name={ "name" }/>
                                    <Field fullWidth className={ classes.textInput }
                                           label={ "Kod rejestracji" }
                                           as={ TextField }
                                           name={ "validation_key" }/>

                                    <Button onClick={ () => create(values) } className={ classes.button }
                                            variant={ "contained" } fullWidth color={ "primary" }> Dodaj firmę </Button>
                                    <Button onClick={ () => setOpen(false) } className={ classes.button }
                                            variant={ "outlined" } fullWidth color={ "secondary" }> Anuluj </Button>
                                </Form>
                        ) }
                    </Formik>
                </DialogContent>
            </Dialog>
    )
}
