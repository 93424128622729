// @flow
import * as React                          from 'react'
import { useContext, useEffect, useState } from 'react'
import {
    Button,
    Card,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
}                                          from "@material-ui/core"
import { useParams }                       from "react-router-dom"
import firebase                            from "firebase/app"
import { Company }                         from "../models/company"
import { AddCompanyDialog }                from "../components/add-company"
import { AuthProviderContext }             from "../providers/AuthProvider"
import EditIcon                            from '@material-ui/icons/Edit'
import SaveIcon                            from '@material-ui/icons/Save'
import ClearIcon                           from '@material-ui/icons/Clear'

type Props = {
    id: string
};

export function CompanyDetail() {
    const { id } = useParams<{ id: string }>()
    return <CompanyDetailWidget id={ id }/>
}

export function CompanyDetailWidget({ id }: Props) {
    const { auth } = useContext(AuthProviderContext)
    const [ company, setCompany ] = useState<Company>()
    const [ companies, setCompanies ] = useState<Company[]>([])
    const [ dialog, setDialog ] = useState(false)

    const getCompany = React.useMemo(() => async () => {
        const res = await firebase.firestore().collection("companies").doc(id).get()
        const l_company = res.data() as Company
        l_company.id = res.id
        setCompany(l_company)
    }, [id]);

    const canEdit = () => {
        return ((auth.user?.company === company?.id) && auth.user?.perms.manage_company) || auth.user?.perms.god
    }

    const getChildCompanies = React.useMemo(() => async () => {
        console.log(`/companies/${ id }/contractors`)
        const l_companies = await firebase.firestore()
                                          .collection("companies")
                                          .doc(id)
                                          .collection("contractors")
                                          .get()

        setCompanies([])
        l_companies.forEach(value => {
            const company = value.data() as Company
            company.id = value.id
            setCompanies(currentCompanies => [ company, ...currentCompanies ])
        })
    }, [id]);

    useEffect(() => {
        Promise.all([
            getCompany(),
            getChildCompanies()
        ]).catch(e => console.error(e));
    }, [ getCompany, getChildCompanies ])

    if (!company) return <h1> Ładowanie </h1>

    return (
            <>
                <Card style={ { padding: "2em", width: "100%", height: 700 } }>
                    <Typography variant={ "h3" }>{ company.name }</Typography>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Kod dostępu</TableCell>
                                    { auth.user?.perms.manage_company || auth.user?.perms.god ?
                                            <EditCompanyField company={ company }
                                                              onSave={ () => getCompany() }
                                                              field={ "validation_key" }
                                                              companyRef={ firebase.firestore()
                                                                                   .doc(`companies/${ id }`) }/> :
                                            <TableCell>{ company.name }</TableCell>
                                    }
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant={ "h5" } style={ { marginTop: "2em" } }> Firmy współpracujące </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa</TableCell>
                                    <TableCell>Klucz rejestracji</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    companies.map(c => <TableRow key={ Math.random() }>
                                        { auth.user?.perms.manage_company || auth.user?.perms.god ?
                                                <EditCompanyField company={ c }
                                                                  onSave={ () => getChildCompanies() }
                                                                  field={ "name" }
                                                                  companyRef={ firebase.firestore()
                                                                                       .doc(`companies/${ id }/contractors/${ c.id }`) }/> :
                                                <TableCell>{ company.name }</TableCell>
                                        }
                                        { auth.user?.perms.manage_company || auth.user?.perms.god ?
                                                <EditCompanyField company={ c }
                                                                  onSave={ () => getChildCompanies() }
                                                                  field={ "validation_key" }
                                                                  companyRef={ firebase.firestore()
                                                                                       .doc(`companies/${ id }/contractors/${ c.id }`) }/> :
                                                <TableCell>{ company?.validation_key }</TableCell>
                                        }
                                    </TableRow>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

                { canEdit() ?
                        <Button color={ "primary" }
                                variant={ "contained" }
                                style={ { marginTop: "1em" } }
                                onClick={ () => setDialog(true) }>
                            Dodaj firmę współpracującą
                        </Button> : null }


                <AddCompanyDialog open={ dialog }
                                  setOpen={ setDialog }
                                  collection={ firebase.firestore()
                                                       .collection("companies")
                                                       .doc(id)
                                                       .collection("contractors") }/>
            </>
    )
}

type EditCompanyNameFieldProps = {
    company: Company,
    companyRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    onSave: () => any,
    field: string
}

export function EditCompanyField({ company, companyRef, onSave, field }: EditCompanyNameFieldProps) {
    const [ isEditing, setIsEditing ] = useState(false)
    const [ newName, setNewName ] = useState(company[field])

    const updateCompany = async () => {
        const payload: any = {}
        payload[field] = newName
        await companyRef.update(payload)

        setIsEditing(false)
        setNewName(company[field])
        onSave()
    }

    if (isEditing)
        return <TableCell>
            <TextField value={ newName } onChange={ e => setNewName(e.target.value) }/>
            <IconButton color={ "primary" } onClick={ () => updateCompany() }>
                <SaveIcon/>
            </IconButton>

            <IconButton color={ "secondary" } onClick={ () => {
                setIsEditing(false)
                setNewName(company[field])
            } }>
                <ClearIcon/>
            </IconButton>
        </TableCell>


    return <TableCell>
        <IconButton onClick={ () => setIsEditing(true) }> <EditIcon/> </IconButton>
        { company[field] }
    </TableCell>
}
