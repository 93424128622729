// @flow
import * as React                          from 'react'
import { useContext, useEffect, useState } from 'react'
import firebase                            from "firebase"
import { DrsmDevice }                      from "../models/drsm"
import { DataGrid, GridColDef }            from "@material-ui/data-grid"
import { Button, ButtonGroup, TextField }  from "@material-ui/core"
import { useHistory }                      from "react-router-dom"
import { AuthProviderContext }             from "../providers/AuthProvider"
import { KompanyKesz, ZoneKesz }           from "../utils/kesz"
import { Company }                         from "../models/company"

type Props = {}

export function Drsm(_: Props) {
    const { auth } = useContext(AuthProviderContext)
    const [ devices, setDevices ] = useState<DrsmDevice[]>([])
    const [ isLoading, setIsLoading ] = useState(true)
    const [ filterValue, setFilterValue ] = useState("")
    const history = useHistory()

    const columns: GridColDef[] = [
        { field: "id", headerName: "Id", flex: 1, sortable: false, editable: false, filterable: false },
        {
            field   : "zone", headerName: "Strefa", flex: 1, renderCell: params => (
                    <p>{ params.row.last_known_zone?.name ?? "Nieprzypisane" }</p>
            ),
            sortable: false, editable: false, filterable: false
        },
        {
            field   : "company", headerName: "Firma", flex: 1, renderCell: params => (
                    <p>{ params.row.owner_company?.name ?? "Brak ostatnio znanej strefy" }</p>
            ),
            sortable: false, editable: false, filterable: false
        },
        {
            field: "last_connect", headerName: "Ostatnie połączenie", flex: 1, renderCell: params => (
                    <p> { params.row?.last_connect_time ? new Date(
                            params.row.last_connect_time.seconds * 1000).toLocaleString("pl") : null } </p>
            )
        },
        {
            field      : "details", headerName: "Akcje", flex: 1, renderCell: params => (
                    <ButtonGroup>
                        <Button onClick={ () => history.push(`/drsm/${ encodeURI(params.row.dbId) }`) }>Detale</Button>
                    </ButtonGroup>
            ), sortable: false, editable: false, filterable: false
        }

    ]

    const createDevice = async (deviceData: firebase.firestore.DocumentData, id: string) => {
        try {
            const company = await KompanyKesz.get(deviceData["owner_company"]["id"])
            const zone = await ZoneKesz.get(deviceData?.last_known_zone?.id)
            const device = deviceData as DrsmDevice

            device.owner_company = company!
            device.last_known_zone = zone!
            device.dbId = id

            setDevices(currentDevices => [ device, ...currentDevices ])
        } catch (e) { console.error(`Cannot load DRSM ${ id }`, e) }
    }

    const loadUserCompany = React.useMemo(() => async () => {
        return await KompanyKesz.get(auth.user!.company) as Company;
    }, [auth]);

    const deviceQuery = React.useMemo(() => async () => {
        const company = await loadUserCompany()
        const ref = firebase.firestore().collection("drsm-device")
        if (auth.user?.perms.god || company.is_managing_company)
            return ref

        return ref.where("owner_company", "==", firebase.firestore().collection("companies")
                                                        .doc(auth.user?.company))
    }, [auth, loadUserCompany]);

    const loadDrsms = React.useMemo(() => async () => {
        setIsLoading(true)
        const devices = await (await deviceQuery()).get();

        const promises: Promise<void>[] = []

        devices.forEach((item) => {
            promises.push(createDevice(item.data(), item.id))
        })

        await Promise.all(promises)
        setIsLoading(false)
    }, [deviceQuery]);

    useEffect(() => {
        if (!auth.isLoggedIn) return
        setDevices([])
        loadDrsms()
    }, [ auth, loadDrsms ]);

    return (
            <div style={ { width: "100%", height: 700 } }>
                <TextField fullWidth style={ { marginBottom: ".5em" } } variant={ "outlined" }
                           helperText={ "Filtrowanie po id i nazwie strefy. Jeżeli chcesz wyświetlić urządzenia bez" +
                                   " przypisanej strefy wpisz 'nieprzypisane'" }
                           onChange={ e => setFilterValue(e.target.value.toLowerCase()) }/>
                <DataGrid hideFooterSelectedRowCount
                          key={ 1 }
                          disableColumnMenu
                          loading={ isLoading }
                          rows={ devices.filter((device) => {
                              if (filterValue === "") return true
                              if ((filterValue === "nieprzypisane" || filterValue === "nieprzypisany") && !device?.last_known_zone) return true

                              const condition1 = device.id.toLowerCase().includes(filterValue)
                              const condition2 = device.dbId.toLowerCase().includes(filterValue)
                              const condition3 = device?.last_known_zone?.name?.toLowerCase().includes(filterValue)

                              return condition1 || condition2 || condition3
                          }) }
                          pagination={ true }
                          pageSize={ 20 }
                          hideFooterRowCount
                          columns={ columns }/>
            </div>
    )
}
