// @flow
import * as React              from 'react'
import { Appbar }              from "./appbar"
import { RouterComponent }     from "./router"
import { createStyles, Theme } from "@material-ui/core/styles"
import { makeStyles }          from "@material-ui/core"

type Props = {};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root       : {
        display: 'flex',
        // flexDirection: 'column',
        minHeight: '100vh',
    },
    content: {
        flexGrow       : 1,
        display        : 'flex',
        flexDirection  : 'column',
        backgroundColor: theme.palette.background.default,
        padding        : theme.spacing(3),
        paddingBottom  : `calc(${theme.spacing(3)}px + 1em)`
    },
    toolbar: theme.mixins.toolbar,
}))

export function Skeleton(props: Props) {
    const styles = useStyles()

    return <>
        <div className={styles.root}>
            <Appbar/>
            <main className={styles.content}>
                <div className={styles.toolbar}/>
                <RouterComponent/>
            </main>
        </div>
    </>
}
