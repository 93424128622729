// @flow
import * as React                from 'react'
import { useContext, useEffect } from 'react'
import { AuthProviderContext }   from "../providers/AuthProvider"
import firebase                  from "firebase"
import { useHistory }            from "react-router-dom"

type Props = {};

export function Logout(props: Props) {
    const { setAuth } = useContext(AuthProviderContext)
    const history = useHistory()

    useEffect(() => {
        firebase.auth().signOut().then(() => {
            setAuth({ isLoggedIn: false, isLoading: false })
            history.push("/login")
        })
    })

    return (
            <div/>
    )
}
