// @flow
import * as React                          from 'react'
import { useContext, useEffect, useState } from 'react'
import { AuthProviderContext }             from "../providers/AuthProvider"
import {
    Button,
    Card,
    Grid,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
}                                          from "@material-ui/core"
import { useParams }                       from "react-router-dom"
import { ExtUser }                         from "../models/extUser"
import firebase                            from "firebase"
import { Company }                         from "../models/company"
import { Alert }                           from "@material-ui/lab"
import { ResetPasswordDialog }             from "./users"

type Props = {};

export function User(_: Props) {
    const { id } = useParams<{ id: string }>()

    const { auth } = useContext(AuthProviderContext)
    const [ user, setUser ] = useState<ExtUser>()
    const [ changedValues, setChangedValues ] = useState<Record<string, boolean | string>>({})
    const [ dialog, setDialog ] = useState(false)
    const [ resetPassword, setResetPassword ] = useState(false)

    const createUser = React.useMemo(() => async (userData: firebase.firestore.DocumentData) => {
        const company = (await userData["company"].get()).data() as Company
        const contractor = (await userData["contractor"]?.get())?.data() as Company

        const user = userData as ExtUser
        user.company = company
        user.contractor = contractor
        user.id = user.user_id
        setUser(user)
    }, []);

    const loadUser = React.useMemo(() => async () => {
        const response = await firebase.firestore().collection("extended-users").doc(id).get()
        await createUser(response.data()!)
    }, [createUser, id]);

    const updateUser = React.useMemo(() => async () => {
        if (window.confirm("Czy jesteś pewien że chcesz zmienić parametry użytkownika ?")) {
            await firebase.firestore().collection("extended-users").doc(id).update(changedValues)
            setDialog(true)
            await loadUser()
        }
    }, [id, changedValues, loadUser]);

    const areChanges = React.useMemo(() => (): boolean => {
        for (const key in changedValues) {
            if (changedValues[key] !== user![key]) return true
        }
        return false
    }, [changedValues, user]);

    useEffect(() => {
        loadUser().catch(e => console.error(e));
    }, [loadUser]);

    if (!user) return <h1>Ładowanie</h1>

    return (
            <Card elevation={ 10 } style={ { padding: "5em", marginLeft: "auto" } }>
                <Typography variant={ "h3" }> Profil użytkownika { user.first_name } { user.last_name }  </Typography>
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 }>
                        <Typography variant={ "h4" }> Dane użytkownika </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> Pole </TableCell>
                                        <TableCell> Wartość </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StringSettingsChanger user={ user } name={ "first_name" }
                                                           setChangedValues={ setChangedValues }
                                                           changedValues={ changedValues }/>
                                    <StringSettingsChanger user={ user } name={ "last_name" }
                                                           setChangedValues={ setChangedValues }
                                                           changedValues={ changedValues }/>
                                    <TableRow>
                                        <TableCell>Firma</TableCell>
                                        <TableCell>{ user.company.name }</TableCell>
                                    </TableRow>
                                    {
                                        user?.contractor ?
                                                <TableRow>
                                                    <TableCell>Firma współpracująca</TableCell>
                                                    <TableCell>{ user.contractor.name }</TableCell>
                                                </TableRow> :
                                                null
                                    }

                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{ user.email }</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </TableContainer>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <Typography variant={ "h4" }> Uprawnienia </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> Uprawnienie </TableCell>
                                        <TableCell> Wartość </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { auth.user?.perms.manage_perms ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_can_assign_perms" }/> : null
                                    }
                                    { auth.user?.perms.upload_firmware ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_upload_firmware" }/> : null
                                    }
                                    { auth.user?.perms.connect_drsm ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_connect_to_drsm" }/> : null
                                    }
                                    { auth.user?.perms.device_settings ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_device_settings" }/> : null
                                    }
                                    { auth.user?.perms.manage_company ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_manage_own_company" }/> : null
                                    }
                                    { auth.user?.perms.read_device_log ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_read_device_log" }/> : null
                                    }
                                    { auth.user?.perms.use_web ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_use_web" }/> : null
                                    }
                                    { auth.user?.perms.edit_zones ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_edit_zones" }/> : null
                                    }
                                    { auth.user?.perms.god ?
                                            <SettingsChanger changedValues={ changedValues }
                                                             setChangedValues={ setChangedValues }
                                                             user={ user } name={ "perm_god" }/> : null
                                    }
                                </TableBody>
                            </Table>

                        </TableContainer>
                    </Grid>
                </Grid>

                <Button onClick={ () => updateUser() }
                        variant={ "contained" }
                        color={ "primary" }
                        disabled={ !areChanges() }> Zapisz zmiany
                </Button>

                <Button onClick={ () => setResetPassword(true) }
                        variant={ "contained" }
                        color={ "primary" }>
                    Zresetuj hasło
                </Button>

                <ResetPasswordDialog open={ resetPassword } setOpen={ setResetPassword } selectedUid={ user }/>

                <Snackbar autoHideDuration={ 5000 } open={ dialog } onClose={ () => setDialog(false) }>
                    <Alert severity={ "success" }> Zmiana ustawień przebiegła pomyślnie </Alert>
                </Snackbar>
            </Card>
    )
}

type SettingsChangerProps = {
    user: ExtUser,
    name: string,
    changedValues: Record<string, boolean | string>,
    setChangedValues: (value: Record<string, boolean | string>) => void
}

const settingsTranslation = new Map<string, string>([
    [ "perm_connect_to_drsm", "Może łączyć się z urządzeniem DRSM" ],
    [ "perm_can_assign_perms", "Może przyznawać uprawnienia" ],
    [ "perm_god", "Jest administratorem" ],
    [ "perm_upload_firmware", "Może wgrywać oprogramowanie" ],
    [ "first_name", "Imię" ],
    [ "last_name", "Nazwisko" ],
    [ "perm_device_settings", "Ma dostęp do zaawansowanych ustawień DRSM" ],
    [ "perm_manage_own_company", "Może zarządzać swoją firmą" ],
    [ "perm_read_device_log", "Odczyt log z urządzeń" ],
    [ "perm_use_web", "Może korzystać z aplikacji www" ],
    [ "perm_edit_zones", "Może edytować strefy" ],
])


function SettingsChanger({ setChangedValues, changedValues, user, name }: SettingsChangerProps) {
    const { auth } = useContext(AuthProviderContext)
    const [ localState, setLocalState ] = useState(user[name])

    useEffect(() => {
        setLocalState(user[name])
    }, [ user, name ])

    const onChange = (_: any, value: boolean) => {
        const local: Record<string, boolean> = {}
        local[name] = value

        setChangedValues({ ...changedValues, ...local })
        setLocalState(value)
    }

    const hasChanged = (): boolean => {
        return localState !== user[name]
    }

    return <TableRow>
        <TableCell> { settingsTranslation.get(name) ?? name } </TableCell>
        {
            auth.user?.perms.god || (auth.user?.perms.manage_perms && name !== "perm_god") ?
                    <TableCell>
                        <Switch onChange={ onChange } checked={ localState }/>
                        { hasChanged() ? "*" : "" }
                    </TableCell> :
                    <TableCell> { user[name] ? "Przyzname" : "Nie przyzname" }</TableCell>
        }
    </TableRow>
}


function StringSettingsChanger({ setChangedValues, changedValues, user, name }: SettingsChangerProps) {
    const { auth } = useContext(AuthProviderContext)
    const [ local, setLocal ] = useState(user[name])

    const onChange = (value: string) => {
        const local_: Record<string, string> = {}
        local_[name] = value
        setLocal(value)
        setChangedValues({ ...changedValues, ...local_ })
    }

    // const hasChanged = (key: string) => {
    //     return user[name] !== local
    // }

    return <TableRow>
        <TableCell>
            { settingsTranslation.has(name) ? settingsTranslation.get(name) : name }
        </TableCell>
        <TableCell>
            { auth.user?.perms.god || auth.user?.perms.manage_perms ?
                    <TextField value={ local } onChange={ (e) => onChange(e.target.value) }/> :
                    <p>{ user[name] }</p>
            }
        </TableCell>
    </TableRow>
}
