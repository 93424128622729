import { Mutex } from "async-mutex";
import { MyEmitter } from "../utils/MyEmitter";
import { Service, ServiceDef, ServiceDefBase } from "./Service";

export type ServiceDefs<T> = {
    [S in keyof T]: T[S] extends ServiceDef<T[S]> ? ServiceDef<T[S]> : undefined;
}

export type DeviceServices<T> = {
    [S in keyof T]?: T[S] extends ServiceDefBase<infer Def> ? Service<Def> : undefined;
};
type ServiceType<T> = T extends ServiceDefBase<infer Def> ? Service<ServiceDefBase<Def>> : undefined;

export interface DeviceEvents
{
    connect: void,
    disconnect: void,
}

export class Device<T> extends MyEmitter<DeviceEvents>
{
    private _device: BluetoothDevice;
    private _def: ServiceDefs<T>;
    private _services: DeviceServices<T>;
    private _mutex: Mutex;

    constructor(device: BluetoothDevice, definition: ServiceDefs<T>)
    {
        super();
        this._device = device;
        this._def = definition;
        this._services = {};
        this._mutex = new Mutex();
    }

    public async runExclusive(fn: () => void | Promise<void>)
    {
        await this._mutex.runExclusive(fn);
    }

    public getService<S>(name: keyof T): ServiceType<S> | undefined
    {
        return this._services[name] as any;
    }

    public get gatt()
    {
        const gatt = this._device.gatt;
        if (! gatt)
            throw new Error(`Device doesn't have GATT server!`);
        
        return gatt;
    }

    public async connect()
    {
        console.log('Connecting to gatt', this);
        const gatt = this.gatt;
        await gatt.connect();

        for (const key in this._def)
        {
            try {
                console.log(`Initializing service ${key}`);
                const def = this._def[key];
                if (def)
                {
                    const service = new Service<any>(def);
                    await service.init(this);

                    this._services[key] = service as any;
                }
            } 
            catch(e)
            {
                console.warn(`Device does not have service ${this._def[key]}`, e);
            }
        }

        this._device.addEventListener('gattserverdisconnected', () => this.emit('disconnect', undefined));
        this.emit('connect', undefined);
    }

    public disconnect()
    {
        if (this._device.gatt?.connected)
            this._device.gatt.disconnect();
    }
}