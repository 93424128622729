// @flow
import * as React                            from 'react'
import { useEffect, useMemo, useState }      from 'react'
import { AuthProvider, AuthProviderContext } from "../providers/AuthProvider"
import { loginFromStorage }                  from "../utils/authUtils"

type Props = {
    children: React.ReactNode
};

export function AuthProviderComponent(props: Props) {
    const [ auth, setAuth ] = useState<AuthProvider>({ isLoggedIn: false, isLoading: true })
    const providerValue = useMemo(() => ({ auth, setAuth }), [ auth ])

    useEffect(() => {
        const loadUser = async () => {
            const storedUser = await loginFromStorage()
            if (storedUser) {
                setAuth(storedUser)
                console.log(storedUser)
            } else {
                setAuth(original => ({ ...original, isLoading: false }))
            }
        }

        loadUser().catch(e => console.error(e));
    }, []);

    return (
            <AuthProviderContext.Provider value={ providerValue }>
                { props.children }
            </AuthProviderContext.Provider>
    )
}
