// @flow
import { Box, Button, Card, CardContent, CircularProgress, makeStyles, Paper, Typography } from '@material-ui/core'
import * as React                          from 'react'
import { useDeviceSearch, useDrsmAuthentication } from '../ble/hooks'
import { DrsmCard } from '../components/DrsmCard';
import { DrsmServices } from '../constants/bleDefs'
import { AuthProviderContext } from '../providers/AuthProvider';
import { KompanyKesz } from '../utils/kesz';

export interface ReadDrsmLogProps
{

}

const useStyles = makeStyles(theme => ({
    wrapper: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
}));

// eslint-disable-next-line no-empty-pattern
export function ReadDrsmLog({}: ReadDrsmLogProps)
{
    const classes = useStyles();
    const [
        hasWebBluetooth,
        isBluetoothAvailable,
        isSearchActive,
        searchError,
        connected,
        device,
        startSearch
    ] = useDeviceSearch(DrsmServices, DrsmServices.status);
    const [
        permissions,
        authError,
        login
    ] = useDrsmAuthentication(device);
    const { auth } = React.useContext(AuthProviderContext);

    React.useEffect(() => {
        let active = true;
        work();
        return () => { active = false; };

        async function work()
        {
            if (device)
            {
                console.log('Logging in');
                const company = (await KompanyKesz.get(auth.user!.company))!;
                if (! active) return;
                await login({ id: company.device_password_key, password: company.device_password });
                console.log('Logged in');
            }
        }
    }, [device, auth, login]);

    React.useEffect(() => {
        if (device) {
            return () => device.disconnect();
        }
    }, [device])

    if (! hasWebBluetooth) {
        return (
            <Box className={classes.wrapper}>
                <Typography variant='h4'>Odczyt log z DRSM</Typography>
                <Typography variant='body1' color='error'>Twoja przeglądarka nie obsługuje Web Bluetooth</Typography>
            </Box>
        );
    } else if (! isBluetoothAvailable) {
        return (
            <Box className={classes.wrapper}>
                <Typography variant='h4'>Odczyt log z DRSM</Typography>
                <Typography variant='body1' color='error'>Aby skorzystać z tej funckjonalności musisz włączyć bluetooth w Twoim urządzeniu.</Typography>
            </Box>
        );
    } else if (connected && !device) {
        return (
            <Box className={classes.wrapper}>
                <Typography variant='h4'>Odczyt log z DRSM</Typography>
                <Card>
                    <CardContent>
                        <Typography variant='body1'><CircularProgress size='1.2em' />&nbsp;Trwa łączenie z urządzeniem</Typography>
                    </CardContent>
                </Card>
            </Box>
        );
    } else if (isSearchActive) {
        return (
            <Box className={classes.wrapper}>
                <Typography variant='h4'>Odczyt log z DRSM</Typography>
                <Typography variant='body1'>Wybierz urządzenie w oknie dialogowym przeglądarki</Typography>
            </Box>
        );
    } else if (device) {
        return (
            <Box className={classes.wrapper}>
                <Typography variant='h4'>Odczyt log z DRSM</Typography>
                <DrsmCard device={device as any} permissions={permissions} onDisconnectClick={() => device?.disconnect()} />
            </Box>
        );
    } else {
        return (
            <Box className={classes.wrapper}>
                <Typography variant='h4'>Odczyt log z DRSM</Typography>
                {searchError && <Typography variant='body1' color='error'>Błąd: {searchError}</Typography>}
                <Button onClick={startSearch} variant='contained' color='primary' style={{ maxWidth: 400 }}>Połącz z urządzeniem</Button>
            </Box>
        )
    }
}