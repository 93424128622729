import { useEffect, useMemo, useState } from "react";
import { AuthNonceCharacteristic, AuthPermissionsCharacteristic, AuthService, AuthSignatureCharacteristic, DrsmKey, DrsmServices, PermissionsValue } from "../constants/bleDefs";
import { Characteristic, CharacteristicValueType } from "./Characteristic";
import { Device, ServiceDefs } from "./Device";
import { ServiceDefBase } from "./Service";

export type UseDeviceSearch<S> = [
    boolean, // is web-bluetooth available
    boolean, // is bluetooth available
    boolean, // isSearchActive
    string | undefined, // searchError
    boolean, // isDeviceConnected
    Device<S> | undefined, // connectedDevice
    () => Promise<Device<S> | undefined>, // startSearch
];

export function useDeviceSearch<D, S>(defs: ServiceDefs<D>, primaryService: ServiceDefBase<S>): UseDeviceSearch<D>
{
    const [isBluetoothAvailable, setIsBluetoothAvailable] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchError, setSearchError] = useState<string>();
    const [connected, setConnected] = useState(false);
    const [device, setDevice] = useState<Device<D>>();

    const hasWebBluetooth = !!navigator.bluetooth;

    useEffect(() => {
        if (! navigator.bluetooth)
            return;
        
        const availibilityChangedHandler = () => {
            navigator.bluetooth.getAvailability().then(setIsBluetoothAvailable);
        }
        navigator.bluetooth.addEventListener('availabilitychanged', availibilityChangedHandler);

        availibilityChangedHandler();

        return () => {
            navigator.bluetooth.removeEventListener('availabilitychanged', availibilityChangedHandler);
        };
    }, []);

    const startSearch = async () => {
        let ret : Device<D>|undefined;

        try 
        {
            if (! navigator.bluetooth)
                throw new Error('Web bluetooth is not available!');
            
            setIsSearchActive(true);
            if (device)
                device.disconnect();

            const btDevice = await navigator.bluetooth.requestDevice({
                filters: [
                    { services: [ primaryService.uuid ] }
                ],
                optionalServices: Object.getOwnPropertyNames(defs).map((d => (defs as any)[d]?.uuid) as ((a: string) => string)).filter(d => !!d),
            });

            const bleDev = new Device<D>(btDevice, defs);
            try 
            {
                setConnected(true);

                await bleDev.connect();
                bleDev.on('disconnect', () => { setConnected(false); setDevice(undefined); });

                setDevice(bleDev);
                ret = bleDev;
            }
            catch(e) 
            {
                console.warn('Error connecting to device', e);

                if (btDevice.gatt?.connected) {
                    btDevice.gatt.disconnect();
                }
                throw e;
            }
        }
        catch(e: Error | any)
        {
            console.warn('Unexpected search error', e);
            setSearchError(`Unexpected error: ${e.error ? e.error : e}`);
            setConnected(false);
            setDevice(undefined);
        }
        setIsSearchActive(false);
        return ret;
    }

    return [
        hasWebBluetooth,
        isBluetoothAvailable,
        isSearchActive,
        searchError,
        connected,
        device,
        startSearch,
    ]
}

export type UseCharValue<D> = [
    CharacteristicValueType<D> | undefined, // value
    string | undefined, // error
    () => void, // reload
];

export function useCharValue<D, E = D>(char: Characteristic<D, E>, subscribe: boolean = false): UseCharValue<D>
{
    const [value, setValue] = useState<CharacteristicValueType<D>>();
    const [error, setError] = useState<string>();

    const refresh = useMemo(() => async () => {
        try
        {
            setValue(await char.read());
        }
        catch(e: any)
        {
            setError(e.message ? e.message : String(e));
        }
    }, [char]);

    useEffect(() => {
        if (subscribe)
        {
            const ret = char.enableNotifications(setValue);

            return () => {
                ret.then(cancel => cancel()).catch(e => console.error(e));
            }
        }
        refresh();
    }, [char, subscribe, refresh]);

    return [
        value,
        error,
        () => refresh(),
    ];
}

export type UseDrsmAuthentication = [
    PermissionsValue, // Current permissions
    string | undefined, // Login error
    (key: DrsmKey) => Promise<void>, // LogIn
];

export function useDrsmAuthentication(drsm?: Device<typeof DrsmServices>): UseDrsmAuthentication
{
    const [permissions, setPermissions] = useState<PermissionsValue>({
        config_low_level: false,
        config_radio: false,
        config_zone: false,
        read_log: false,
        read_status: false,
        test_modes: false,
        upgrade_firmware: false
    });
    const [error, setError] = useState<string>();
    const auth = useMemo(() => drsm?.getService<AuthService>('auth'), [drsm]);
    const chNonce = useMemo(() => auth?.getCharacteristic<AuthNonceCharacteristic>('nonce'), [auth]);
    const chSignature = useMemo(() => auth?.getCharacteristic<AuthSignatureCharacteristic>('signature'), [auth]);
    const chPermissions = useMemo(() => auth?.getCharacteristic<AuthPermissionsCharacteristic>('permissions'), [auth]);

    useEffect(() => {
        (async () => {
            if (! drsm || ! chPermissions) {
                console.log('No DRSM, resetting permissions');
                setPermissions({
                    config_low_level: false,
                    config_radio: false,
                    config_zone: false,
                    read_log: false,
                    read_status: false,
                    test_modes: false,
                    upgrade_firmware: false
                });
            } else {
                try {
                    console.log('DRSM connected, reading permissions');
                    const permissions = await chPermissions.read();
                    setPermissions(permissions);
                    console.log('Permissions', permissions);
                }
                catch(e) {
                    console.error('Failed to read permissions', e);
                }
            }
            })();
    }, [drsm, chPermissions]);

    const login = useMemo(() => async (key: DrsmKey) => {
        try
        {
            if (chSignature && chNonce && chPermissions)
            {
                console.log('Logging in', key);
                const nonce = await chNonce.read();
                await chSignature.write({ nonce, key });
                const permissions = await chPermissions.read();
                console.log('Permissions after login', permissions);
                setPermissions(permissions);
            }
            else
            {
                throw new Error('Cannot login without connected device');
            }
        }
        catch (e: Error | any)
        {
            console.warn('Login error', e);
            setError(e instanceof Error ? e.message : String(e));
        }
    }, [chNonce, chPermissions, chSignature]);

    return [
        permissions,
        error,
        login,
    ];
}