// @flow
import * as React                                                  from 'react'
import { useContext, useEffect, useState }                         from 'react'
import { Button, Card, Checkbox, Snackbar, TextField, Typography } from "@material-ui/core"
import * as yup                                                    from "yup"
import { Field, Form, Formik, FormikValues }                       from 'formik'
import { makeStyles }                                              from "@material-ui/core/styles"
import { AuthProviderContext }                                     from "../providers/AuthProvider"
import { login }                                                   from "../utils/authUtils"
import { Alert }                                                   from "@material-ui/lab"
import { useHistory }                                              from "react-router-dom"


type Props = {};

const useStyle = makeStyles({
    main       : {
        padding: "2em"
    },
    form       : {
        display      : "flex",
        flexDirection: "column",
        width        : "max(40vw, 250px)"
    },
    textInput  : {
        width : "100%",
        margin: "10px 1em"
    },
    loginButton: {
        width          : "100%",
        backgroundColor: "forestgreen",
        color          : "white"
    }

})

const initialFormValues = {
    email       : "",
    password    : "",
    keepLoggedIn: false
}

const validationSchema = yup.object({
    email       : yup.string().required().email().max(150),
    password    : yup.string().required().max(150),
    keepLoggedIn: yup.boolean()
})

export function Login(props: Props) {
    const classes = useStyle()
    const history = useHistory()
    const { auth, setAuth } = useContext(AuthProviderContext)
    const [ snackOpen, setSnackOpen ] = useState(false)

    const onSubmit = async (data: FormikValues) => {
        const user = await login(data.email, data.password)
        if (user) {
            setAuth(user)
            history.push("/")
        } else {
            setSnackOpen(true)
        }
    }

    const onSnackClose = (_?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return;

        setSnackOpen(false);
    }

    useEffect(() => {
        console.log(auth)
    }, [auth]);

    return (
            <Card className={ classes.main }>
                <Snackbar anchorOrigin={{vertical: "top", horizontal: "left"}}
                          open={snackOpen}
                          onClose={onSnackClose}
                          autoHideDuration={3000}>
                    <Alert severity={"error"} onClose={onSnackClose}>
                        Nieprawidłowe dane logowania
                    </Alert>
                </Snackbar>

                <Typography variant={ "h3" }>
                    Logowanie
                </Typography>

                <Formik initialValues={ initialFormValues }
                        validationSchema={ validationSchema }
                        onSubmit={ onSubmit }>
                    { ({ errors, touched, isValid }) => (
                            <Form className={ classes.form }>
                                <Field label={ "Email" }
                                       error={ !!errors.email && touched.email }
                                       helperText={ errors.email && touched.email ? errors.email : "" }
                                       as={ TextField }
                                       className={ classes.textInput }
                                       name={ "email" }/>

                                <Field label={ "Hasło" }
                                       error={ !!errors.password && touched.password }
                                       helperText={ errors.password && touched.password ? errors.password : "" }
                                       as={ TextField }
                                       type={ "password" }
                                       className={ classes.textInput }
                                       name={ "password" }/>

                                <label>
                                    <Field label={ "Pozostań zalogowany" }
                                           error={ !!errors.keepLoggedIn && touched.keepLoggedIn ? errors.keepLoggedIn : "" }
                                           as={ Checkbox }
                                           name={ "keepLoggedIn" }/>
                                    Pozostań zalogowany
                                </label>

                                <Button disabled={ !isValid } type={ "submit" }
                                        className={ classes.loginButton }> Login </Button>
                            </Form>
                    ) }
                </Formik>
            </Card>
    )
}
