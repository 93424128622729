// @flow
import * as React             from 'react'
import { Link }               from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"

type Props = {};

export function NoPerms(_: Props) {
    return (
            <div>
                Nie masz pozwoleń do korzystania z aplikacji, jedyne co możesz zrobić to <Link to={ "/change-password" }
                                                                                               component={ RouterLink }>
                zresetować hasło
            </Link>
            </div>
    )
}
