// @flow
import * as React                          from 'react'
import { useContext, useEffect, useState } from 'react'
import {
    Button,
    ButtonGroup,
    Card,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    TextField,
    Tooltip,
    Typography
}                                          from "@material-ui/core"
import firebase                            from "firebase/app"
import { Company }                         from "../models/company"
import DeleteIcon                          from '@material-ui/icons/Delete'
import { AuthProviderContext }             from "../providers/AuthProvider"
import { Alert }                           from "@material-ui/lab"
import { DrsmDevice }                      from "../models/drsm"
import WarningIcon                         from '@material-ui/icons/Warning'
import fileDownload                        from "js-file-download"

type Props = {};


export function DrsmTransfer(_: Props) {
    const transferDispositionRef = firebase.firestore()
                                           .doc("/companies/67R4fUTuVQjq4f87LOjx/private/transferDisposition")

    const { auth } = useContext(AuthProviderContext)
    const [ companies, setCompanies ] = useState<Company[]>([])
    const [ selectedCompany, setSelectedCompany ] = useState("")

    const [ devicesToTransfer, setDevicesToTransfer ] = useState<Set<string>>(new Set<string>())
    const [ currentDevice, setCurrentDevice ] = useState("")

    const [ isErrorSnackOpen, setIsErrorSnackOpen ] = useState(false)
    const [ isWarnSnackOpen, setIsWarnSnackOpen ] = useState(false)
    const [ isSuccSnackOpen, setIsSuccSnackOpen ] = useState(false)

    const [ isSyncedWithCoud, setIsSyncedWithCoud ] = useState(false)

    const getCompanies = React.useMemo(() => async () => {
        const res = await firebase.firestore().collection("/companies").get()
        setCompanies([])
        const data: Company[] = []

        res.forEach(c => {
            const company = c.data() as Company
            company.id = c.id
            data.push(company)
        })

        setCompanies(data)
    }, []);

    const onSelectChange = (e: any) => {
        setSelectedCompany(e.target.value)
    }

    const onTextInputChange = (e: any) => {
        setCurrentDevice(e.target.value)
    }

    const onTextKeyChange = (e: any) => {
        if (e.keyCode === 13 || e.which === 13) {
            addDevice()
        }
    }

    const addDevice = async () => {
        if (currentDevice === "") return
        const dev = await firebase.firestore().doc(`/drsm-device/${ currentDevice }`).get()
        if (!dev.exists) {
            setIsErrorSnackOpen(true)
            return
        }

        if ((dev.data() as DrsmDevice).owner_company.id !== auth.user?.company)
            setIsWarnSnackOpen(true)
        else setIsSuccSnackOpen(true)

        setDevicesToTransfer((new Set(devicesToTransfer)).add(currentDevice))
        setCurrentDevice("")
        setIsSyncedWithCoud(false)
    }

    const transferDevices = async () => {
        if (selectedCompany === "") {
            window.alert("Nie wybrano firmy dla której będą przekazanie urządzenia")
            return
        }

        const promises: Promise<any>[] = []
        devicesToTransfer.forEach(dev => {
            console.log(`/drsm-device/${ dev }`)
            const operation = firebase.firestore().doc(`/drsm-device/${ dev }`).update({
                owner_company: firebase.firestore().doc(`/companies/${ selectedCompany }`)
            }).catch(() => console.error(`No device in system ${ dev }`))
            promises.push(operation)
        })
        await Promise.all(promises)

        await transferDispositionRef.update({ devices: [] })

        setDevicesToTransfer(new Set())

        window.alert("Skutecznie przekzano urządzenia")
    }

    const downloadReport = () => {
        let fileData = ""
        devicesToTransfer.forEach(dev => {
            fileData += dev + "\n"
        })
        fileDownload(fileData, `RAPORT przekaz DRSM ${ new Date().toLocaleDateString("pl") }.txt`)
    }

    const handleClose = (e: any, r: any) => {
        if (r === "clickaway") return
        setIsErrorSnackOpen(false)
        setIsWarnSnackOpen(false)
        setIsSuccSnackOpen(false)
    }

    const saveState = async () => {
        await transferDispositionRef.update({
            devices: [ ...devicesToTransfer ]
        })
        setIsSyncedWithCoud(true)
    }

    const loadState = React.useMemo(() => async () => {
        const res = (await transferDispositionRef.get()).data()
        if (!res) return

        setDevicesToTransfer(new Set<string>(res.devices))
    }, [transferDispositionRef]);

    useEffect(() => {
        (async () => {
            await getCompanies();
            await loadState();
        })().catch(e => console.error(e));
    }, [getCompanies, loadState]);

    return (
            <Card style={ { padding: "3em" } }>
                <Snackbar anchorOrigin={ { horizontal: "center", vertical: "bottom" } } open={ isErrorSnackOpen }
                          autoHideDuration={ 6000 } onClose={ handleClose }>
                    <Alert severity="error" style={ { minWidth: "90vw" } } variant={ "filled" }>
                        Urządzenie: { currentDevice } nie jest wpisane do bazy danych
                    </Alert>
                </Snackbar>
                <Snackbar anchorOrigin={ { horizontal: "center", vertical: "bottom" } } open={ isWarnSnackOpen }
                          autoHideDuration={ 6000 } onClose={ handleClose }>
                    <Alert severity="warning" style={ { minWidth: "90vw" } } variant={ "filled" }>
                        Urządzenie: { currentDevice } jest przypisane do firmy innej niż twoja
                    </Alert>
                </Snackbar>
                <Snackbar anchorOrigin={ { horizontal: "center", vertical: "bottom" } } open={ isSuccSnackOpen }
                          autoHideDuration={ 6000 } onClose={ handleClose }>
                    <Alert severity="success" style={ { minWidth: "90vw" } } variant={ "filled" }>
                        Urządzenie: { currentDevice } zostało prawidłowo dodane
                    </Alert>
                </Snackbar>
                <Typography variant={ "h4" }> Transfer Urządzeń DRSM </Typography>

                <div>
                    <FormControl style={ { marginRight: "20px" } }>
                        <Select style={ { minWidth: "120px" } } value={ selectedCompany } onChange={ onSelectChange }>
                            { companies.map(company => (
                                    <MenuItem key={ company.id } value={ company.id }> { company.name } </MenuItem>
                            )) }
                        </Select>
                        <FormHelperText> Firma otrzymująca urządzenia DRSM </FormHelperText>
                    </FormControl>
                    <TextField value={ currentDevice } onKeyPress={ onTextKeyChange } onChange={ onTextInputChange }
                               helperText={ "UID" +
                                       " Urządzenia do" +
                                       " przekazania" }/>
                    <ButtonGroup style={ { marginLeft: "30px" } }>
                        <Button onClick={ addDevice } color={ "primary" }> Dodaj </Button>
                        <Button onClick={ saveState } color={ "primary" }> Zapisz </Button>
                        <Button onClick={ downloadReport } color={ "primary" }> Pobierz raport przekazania </Button>
                        <Button color={ "primary" } onClick={ () => transferDevices() }> Przekaż urządzenia </Button>
                    </ButtonGroup>
                </div>

                { !isSyncedWithCoud ? <Alert style={ { marginTop: "1em" } } color={ "warning" }>
                    Dane nie są zsynchronizowane z chmurą, pamiętaj żeby je zapisać
                </Alert> : null }

                <Grid container spacing={ 4 } style={ { marginTop: "2em" } }>
                    { [ ...devicesToTransfer ].map(
                            val => <DevCard uid={ val }
                                            key={ val }
                                            devicesToTransfer={ devicesToTransfer }
                                            setIsSynced={ setIsSyncedWithCoud }
                                            setDevicesToTransfer={ setDevicesToTransfer }/>) }
                </Grid>
                <pre>  </pre>
            </Card>
    )
}


type CardProps = {
    uid: string,
    devicesToTransfer: Set<string>
    setDevicesToTransfer: (value: Set<string>) => void,
    setIsSynced: (value: boolean) => void
}

export function DevCard({ uid, devicesToTransfer, setDevicesToTransfer, setIsSynced }: CardProps) {
    const { auth } = useContext(AuthProviderContext)
    const [ devCompany, setDevCompany ] = useState("")

    useEffect(() => {
        firebase.firestore().doc(`/drsm-device/${ uid }`)
                .get()
                .then(res => {
                    setDevCompany((res.data() as DrsmDevice).owner_company.id)
                })
    }, [uid])

    return (
            <Grid key={ uid } item xs={ 2 }>
                <Paper style={ {
                    padding       : "2em 0 2em 2em",
                    display       : "flex",
                    alignItems    : "center",
                    justifyContent: "space-between"
                } }>
                    { devCompany !== auth.user?.company ? <Tooltip title={ "Podane urządzenie jest już przypisane do" +
                            " innej firmy" }>
                        <WarningIcon color={ "error" }/>
                    </Tooltip> : null }
                    <div>{ uid }</div>
                    <Button color={ "secondary" } onClick={ () => {
                        if (!window.confirm("Czy na pewno chcesz usunąć urządzenie")) return
                        const nSet = new Set(devicesToTransfer)
                        nSet.delete(uid)
                        setDevicesToTransfer(nSet)
                        setIsSynced(false)
                    } }>
                        <DeleteIcon/>
                    </Button>
                </Paper>
            </Grid>
    )
}
