import { AppError }   from "../models/appError"
import firebase       from "firebase"
import { ExtUser }    from "../models/extUser"
import { DrsmDevice } from "../models/drsm"


type InnerResponse = firebase.firestore.DocumentReference<firebase.firestore.DocumentData>

abstract class Collection<T> {
    abstract name: string

    protected getData(id: string): InnerResponse {
        return firebase.firestore()
                       .collection(this.name)
                       .doc(id)
    }

    async get(id: string): Promise<T> {
        const data = await this.getData(id).get()
        return data.data() as T
    }

    update(id: string, payload: firebase.firestore.UpdateData) {
        return this.getData(id).update(payload)
    }

    delete(id: string) {
        return this.getData(id).delete()
    }
}

class UserErrorsCollection extends Collection<AppError> {
    name = "user-errors"
}

class ExtendedUserCollection extends Collection<ExtUser> {
    name = "extended-users"

    async get_by(id: string): Promise<ExtUser> {
        const data = await firebase.firestore()
                                   .collection(this.name)
                                   .doc(id)
                                   .get()
        return data?.data() as ExtUser
    }
}

class DrsmDevices extends Collection<DrsmDevice> {
    name = "drsm-device"
}

export class DataBase {
    static userErrors = new UserErrorsCollection()
    static extendedUsers = new ExtendedUserCollection()
    static drsm = new DrsmDevices()
}
