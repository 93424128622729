// @flow
import * as React                          from 'react'
import { useContext, useEffect, useState } from 'react'
import firebase                            from "firebase"
import { Company }                         from "../models/company"
import { DataGrid, GridColDef }            from "@material-ui/data-grid"
import { Button, ButtonGroup }             from "@material-ui/core"
import { useHistory }                      from "react-router-dom"
import { AddCompanyDialog }                from "../components/add-company"
import { AuthProviderContext }             from "../providers/AuthProvider"
import { CompanyDetailWidget }             from "./company-detail"
import { ContractorDetail }                from "./contractor-detail"

type Props = {};

export function Companies() {
    const { auth } = useContext(AuthProviderContext)
    if (auth.user?.perms.god) return <CompaniesWidget/>
    if (auth.user?.contractor) return <ContractorDetail parentId={ auth.user!.company } id={ auth.user!.contractor }/>
    return <CompanyDetailWidget id={ auth.user!.company }/>
}

export function CompaniesWidget(_: Props) {
    const { auth } = useContext(AuthProviderContext)
    const [ companies, setCompanies ] = useState<Company[]>([])
    const [ isLoading, setIsLoading ] = useState(true)
    const [ dialog, setDialog ] = useState(false)
    const history = useHistory()


    const columns: GridColDef[] = [
        { field: "name", headerName: "Nazwa", flex: 1, sortable: false, editable: false, filterable: false },
        {
            field      : "actions", headerName: "Akcje", flex: 1, renderCell: param => (
                    <ButtonGroup><Button onClick={ () => history.push(
                            `/companies/${ param.row.id }`) }>Sczegóły</Button></ButtonGroup>
            ), sortable: false, editable: false, filterable: false,
        }
    ]


    useEffect(() => {
        firebase.firestore().collection("companies").get().then(result => {
            result.forEach(value => {
                const company = value.data() as Company
                company.id = value.id
                setCompanies(currentCompanies => [ company, ...currentCompanies ])
            })
            setIsLoading(false)
        })
    }, [ dialog ])
    return (
            <div style={ { width: "100%", height: 700 } }>
                <DataGrid disableColumnMenu
                          hideFooterRowCount
                          hideFooterSelectedRowCount
                          key={ 1 }
                          loading={ isLoading }
                          rows={ companies } columns={ columns }/>
                {
                    auth.user?.perms.god ? <Button variant={ "contained" }
                                                   color={ "primary" }
                                                   onClick={ () => setDialog(true) }
                                                   style={ { marginTop: "1em" } }>
                        Nowa firma
                    </Button> : null
                }

                <AddCompanyDialog open={ dialog } setOpen={ setDialog }
                                  collection={ firebase.firestore().collection("companies") }/>
            </div>
    )
}
