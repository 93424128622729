// @flow
import * as React                                  from 'react'
import { useContext, useState }                    from 'react'
import { Button, Snackbar, TextField, Typography } from "@material-ui/core"
import * as yup                                    from "yup"
import { Field, Form, Formik }                     from 'formik'
import { makeStyles }                              from "@material-ui/core/styles"
import firebase                                    from "firebase/app"
import { AuthProviderContext }                     from "../providers/AuthProvider"
import { Alert }                                   from "@material-ui/lab"

type Props = {};

const initValues = {
    email       : "",
    oldPassword : "",
    newPassword : "",
    newPassword2: ""
}

const validationSchema = yup.object({
    email       : yup.string().email().required(),
    oldPassword : yup.string().required(),
    newPassword : yup.string().required().min(7),
    newPassword2: yup.string().required().min(7)
})

const useStyles = makeStyles({
    textInput: {
        marginBottom: ".5em"
    }
})

export function ChangePassword(_: Props) {
    const { setAuth } = useContext(AuthProviderContext)
    const [ successOpen, setSuccessOpen ] = useState(false)
    const [ failureOpen, setFailureOpen ] = useState(false)
    const [ failureText, setFailureText ] = useState("")
    const classes = useStyles()

    const submitForm = async (values: Record<string, string>) => {
        const currentUser = await firebase.auth().signInWithEmailAndPassword(values.email, values.oldPassword)
                                          .catch(_ => {
                                              setFailureOpen(true)
                                              setFailureText("Nieprawidłowe hasło")
                                          })

        if (!currentUser) return
        await currentUser.user?.updatePassword(values.newPassword)

        setSuccessOpen(true)
        setAuth({ isLoading: false, isLoggedIn: false, user: undefined })
    }

    const handleClose = () => {
        setFailureOpen(false)
        setSuccessOpen(false)
    }

    return (
            <div>
                <Typography variant={ "h3" }>
                    Zmiana hasła
                </Typography>
                <Snackbar open={ successOpen } onClose={ handleClose } autoHideDuration={ 5000 }>
                    <Alert severity={ "success" } onClose={ handleClose }> Zmiana hasła przebiegła pomyślnie </Alert>
                </Snackbar>

                <Snackbar open={ failureOpen } onClose={ handleClose } autoHideDuration={ 5000 }>
                    <Alert severity={ "error" } onClose={ handleClose }> { failureText } </Alert>
                </Snackbar>
                <Formik initialValues={ initValues } validationSchema={ validationSchema } onSubmit={ submitForm }>
                    { ({ errors, touched, isValid, values }) => (
                            <Form>
                                <Field fullWidth className={ classes.textInput }
                                       label={ "Adres email" }
                                       error={ !!errors.email && touched.email }
                                       helperText={ errors.email && touched.email ? errors.email : "" } as={ TextField }
                                       name={ "email" }/>
                                <Field fullWidth className={ classes.textInput }
                                       label={ "Stare hasło" }
                                       type={ "password" }
                                       error={ !!errors.oldPassword && touched.oldPassword }
                                       helperText={ errors.oldPassword && touched.oldPassword ? errors.oldPassword : "" }
                                       as={ TextField }
                                       name={ "oldPassword" }/>
                                <Field fullWidth className={ classes.textInput }
                                       label={ "Nowe hasło" }
                                       type={ "password" }
                                       error={ !!errors.newPassword && touched.newPassword }
                                       helperText={ errors.newPassword && touched.newPassword ? errors.newPassword : "" }
                                       as={ TextField }
                                       name={ "newPassword" }/>

                                <Field fullWidth className={ classes.textInput }
                                       label={ "Powtórz hasło" }
                                       type={ "password" }
                                       error={ !!errors.newPassword2 && touched.newPassword2 }
                                       helperText={ errors.newPassword2 && touched.newPassword2 ? errors.newPassword2 : "" }
                                       as={ TextField }
                                       name={ "newPassword2" }/>
                                {
                                    (values.newPassword !== values.newPassword2) && touched.newPassword2 ?
                                            <Alert severity={ "error" }> Hasła muszą się zgadzać </Alert> : null
                                }
                                <Button type={ "submit" }
                                        fullWidth
                                        disabled={ !isValid || values.newPassword !== values.newPassword2 }
                                        variant={ "contained" }
                                        color={ "primary" }>
                                    Zmień hasło
                                </Button>
                            </Form>
                    ) }

                </Formik>
            </div>
    )
}
