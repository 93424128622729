// @flow
import * as React                                                                   from 'react'
import { useEffect, useState }                                                      from 'react'
import { DrsmError }                                                                from "../models/drsmError"
import firebase                                                                     from "firebase/app"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import fileDownload                                                                 from "js-file-download"

type Props = {};

export function DrsmErrors(props: Props) {
    const [ errors, setErrors ] = useState<DrsmError[]>([])


    const getErrors = React.useMemo(() => async () => {
        const devs = await firebase.firestore().collection("drsm-error").orderBy("timestamp", "desc").get()

        const local_errors: DrsmError[] = []
        devs.forEach(dev => {
            const err = dev.data() as DrsmError
            err.parameters.serial_number = translateSerialNumber(err.parameters.serial_number)
            local_errors.push(err)
        })
        setErrors(local_errors)
    }, []);

    const translateSerialNumber = (val: number[]) => {
        let str = ""
        for (const n of val) {
            str += String.fromCharCode(n)
        }
        return str
    }

    useEffect(() => {
        getErrors().catch(e => console.error(e));
    }, [getErrors]);

    return (
            <div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> Data zgłoszenia </TableCell>
                                <TableCell> ID </TableCell>
                                <TableCell> Akcje </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                errors.map(err => (
                                        <TableRow key={ Math.random() }>
                                            <TableCell> { new Date(err.timestamp.seconds * 1000).toLocaleString(
                                                    "pl") } </TableCell>
                                            <TableCell> { err.parameters.serial_number } </TableCell>
                                            <TableCell> <Button variant={ "outlined" } onClick={ () => fileDownload(
                                                    JSON.stringify(err, null, 2),
                                                    `${ err.timestamp.seconds }.json`) }> Pobierz dane </Button>
                                            </TableCell>
                                        </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
    )
}
