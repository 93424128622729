import React, { useContext, useState }                                 from 'react'
import { createStyles, makeStyles, Theme }                             from '@material-ui/core/styles'
import AppBar                                                          from '@material-ui/core/AppBar'
import Toolbar                                                         from '@material-ui/core/Toolbar'
import Typography                                                      from '@material-ui/core/Typography'
import MenuIcon                                                        from '@material-ui/icons/Menu'
import ChevronLeftIcon                                                 from '@material-ui/icons/ChevronLeft'
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import { drawerData, DrawerItem }                                      from "../constants/drawerData"
import { Link }                                                        from 'react-router-dom'
import { AuthProviderContext }                                         from "../providers/AuthProvider"

type Props = {};

const drawerWidth = 250

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            wrapper : {
                display: 'flex'
            },
            appBar     : {
                [theme.breakpoints.up('md')] : {
                    width     : `calc(100% - ${ drawerWidth }px)`,
                    marginLeft: drawerWidth,
                }
            },
            drawerWrapper : {
                [theme.breakpoints.up('md')] : {
                    width     : drawerWidth,
                    flexShrink: 0,
                }
            },
            menuButton : {
                [theme.breakpoints.up('xs')] : {
                    display: 'block'
                },
                [theme.breakpoints.up('md')] : {
                    display: 'none',
                }
            },
            permanentDrawer     : {
                [theme.breakpoints.up('xs')] : {
                    display: 'none'
                },
                [theme.breakpoints.up('md')] : {
                    display: 'block',
                }
            },
            temporaryDrawer : {
                [theme.breakpoints.up('xs')] : {
                    display: 'block'
                },
                [theme.breakpoints.up('md')] : {
                    display: 'none',
                }
            },
            drawerPaper: {
                boxSizing: 'border-box',
                width: drawerWidth,
            },
            // necessary for content to be below app bar
            toolbar: theme.mixins.toolbar,
        }),
)

interface DrawerContentProps
{
    onClick?: () => void;
}

function DrawerContent({onClick}: DrawerContentProps) {
    const { auth } = useContext(AuthProviderContext);
    
    const buildListItem = (item: DrawerItem, index: number) => {
        return <ListItem button component={ Link } to={ item.path } key={ index } onClick={onClick}>
            <ListItemIcon>{ item.icon }</ListItemIcon>
            <ListItemText primary={ item.name }/>
        </ListItem>
    }

    return (
        <List>
            { drawerData.filter((item) => {
                return (typeof item.isLoginProtected !== 'boolean' || auth.isLoggedIn === item.isLoginProtected) &&
                        (!item.checkPerms || item.checkPerms(auth))
            }).map((item, index) => {
                if (item.isDivider) return <Divider key={ index }/>
                return buildListItem(item, index)
            }) }
        </List>
    )
}

export function Appbar(_: Props) {
    const classes = useStyles()
    const [ open, setOpen ] = useState(false);

    return (
        <Box className={classes.wrapper}>
            <AppBar position="fixed" className={ classes.appBar }>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(current => !current)}
                        edge="start"
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Hipernet Drsm - Zarządzanie
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box component='nav' className={classes.drawerWrapper}>
                <Drawer
                    className={ classes.temporaryDrawer }
                    variant="temporary"
                    classes={ {
                        paper: classes.drawerPaper,
                    } }
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <IconButton onClick={() => setOpen(false)}>
                        <ChevronLeftIcon />
                    </IconButton>
                    {/* <div className={ classes.toolbar }/> */}
                    <Divider/>
                    <DrawerContent onClick={() => setOpen(false)} />
                </Drawer>
                <Drawer
                    className={ classes.permanentDrawer }
                    variant="permanent"
                    classes={ {
                        paper: classes.drawerPaper,
                    } }
                    anchor="left"
                    open
                >
                    {/* <IconButton onClick={() => setOpen(false)}>
                        <ChevronLeftIcon />
                    </IconButton> */}
                    <div className={ classes.toolbar }/>
                    <Divider/>
                    <DrawerContent />
                </Drawer>
            </Box>
        </Box>
    )
}
