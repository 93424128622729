import { AuthProvider } from "../providers/AuthProvider"
import firebase         from "firebase"
import { DataBase }     from "../db/db"
import { ExtUser }      from "../models/extUser"

const userFromData = (user: ExtUser) => ({
    email     : user.email,
    first_name: user.first_name,
    last_name : user.last_name,
    company   : user.company.id as any,
    contractor: user?.contractor?.id,
    perms     : {
        god            : !!user.perm_god,
        manage_perms   : !!user.perm_can_assign_perms,
        manage_company : !!user.perm_manage_own_company,
        use_web        : !!user.perm_use_web || !!user.perm_god,
        connect_drsm   : !!user.perm_connect_to_drsm,
        upload_firmware: !!user.perm_upload_firmware,
        device_settings: !!user.perm_device_settings,
        read_device_log: !!user.perm_read_device_log,
        edit_zones     : !!user.perm_edit_zones,
    }
})

export const login = async (email: string, password: string): Promise<AuthProvider | null> => {
    try {
        // await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        const {user} = await firebase.auth().signInWithEmailAndPassword(email, password)

        const extUser = await DataBase.extendedUsers.get_by(user!.uid)
        return {
            isLoggedIn: true,
            isLoading : false,
            user      : userFromData(extUser),
        }
    } catch (e) {
        console.log(e);
        return null
    }
}


export const loginFromStorage = async (): Promise<AuthProvider | null> => {
    const user = await getCurrentUser(firebase.auth())
    if (!user) return null

    const extUser = await DataBase.extendedUsers.get_by(user!.uid)
    return {
        isLoggedIn: true,
        isLoading : false,
        user      : userFromData(extUser),
    }
}


function getCurrentUser(auth: firebase.auth.Auth): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            unsubscribe()
            resolve(user!)
        }, reject)
    })
}
