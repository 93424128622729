import EventEmitter from "events";

type EventMap = Record<string, any>;

type EventKey<T extends EventMap> = string & keyof T;
type EventReceiver<T> = (params: T) => void;

interface Emitter<T extends EventMap> {
  on<K extends EventKey<T>>
    (eventName: K, fn: EventReceiver<T[K]>): void;
  off<K extends EventKey<T>>
    (eventName: K, fn: EventReceiver<T[K]>): void;
//   emit<K extends EventKey<T>>
//     (eventName: K, params: T[K]): void;
}

export class MyEmitter<T extends EventMap> implements Emitter<T> {
    private emitter = new EventEmitter();
    public on<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>) {
      this.emitter.on(eventName, fn);
    }
  
    public off<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>) {
      this.emitter.off(eventName, fn);
    }
  
    protected emit<K extends EventKey<T>>(eventName: K, params: T[K]) {
      this.emitter.emit(eventName, params);
    }
  }