// @flow
import * as React                                 from 'react'
import { useContext, useEffect }                  from 'react'
import { Route, RouteComponentProps, useHistory } from "react-router-dom"
import { AuthProvider, AuthProviderContext }                    from "../providers/AuthProvider"

type Props = {
    path: string, 
    requiredPerm?: keyof NonNullable<AuthProvider['user']>['perms'];
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
};

export function IsLoggedInGuardRoute({ path, component, requiredPerm }: Props) {
    const { auth } = useContext(AuthProviderContext)
    const history = useHistory()

    useEffect(() => {
        const handleAuth = () => {
            console.log([ path, auth.user ])
            if (auth.isLoading) return
            if (!auth.isLoggedIn) {
                console.log("Route is guarded")
                history.push(`/login`)
            }
            
            if (requiredPerm && ! (auth.user?.perms[requiredPerm] || auth.user?.perms.god)) {
                console.log("Insufficient permissions, missing", requiredPerm);
                history.push("/no-perms")
            }
    
            if (!auth.user?.perms.use_web && !path.includes("change-password")) {
                history.push("/no-perms")
            }

        }

        handleAuth()
    }, [ auth, history, path, requiredPerm ]);

    if (auth.isLoading) return (<h1> Sprawdzanie uwierzytelnienia </h1>);

    return (<Route path={ path } component={ component }/>);
}
