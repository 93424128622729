// @flow
import * as React from 'react'
import {
    useContext,
    useEffect,
    useState
}                 from 'react'
import firebase   from "firebase"
import {
    ExtUser
}                 from "../models/extUser"
import {
    DataGrid,
    GridColDef
}                 from "@material-ui/data-grid"
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    TextField
}                 from "@material-ui/core"
import {
    AuthProvider,
    AuthProviderContext
}                 from '../providers/AuthProvider'
import axios      from "axios"
import {
    firebasePassReset
}                 from "../constants/urls"
import {
    useHistory
}                 from "react-router-dom"
import {
    KompanyKesz
}                 from "../utils/kesz"


function UsersQuery({ user }: AuthProvider) {
    let base = firebase.firestore().collection("extended-users")
    if (user?.perms.god) return [ base ]
    const contractorQuery = base.where("contractor", "==",
            firebase.firestore().doc(`/companies/${ user?.company }/contractors/${ user?.contractor }`))
    const companyQuery = base.where("company", "==", firebase.firestore().doc(`/companies/${ user?.company }`))

    if (user?.contractor) return [ contractorQuery ]


    return [ companyQuery ]
}


type Props = {};

export function Users(_: Props) {
    const [ users, setUsers ] = useState<ExtUser[]>([])
    const [ isLoading, setIsLoading ] = useState(true)
    const [ openPasswordDialog, setOpenPasswordDialog ] = useState(false)
    const [ selectedUser, setSelectedUser ] = useState<ExtUser>()
    const [ filterValue, setFilterValue ] = useState("")

    const { auth } = useContext(AuthProviderContext)

    const history = useHistory()

    const userIsNew = ({
                           perm_use_web,
                           perm_god,
                           perm_can_assign_perms,
                           perm_connect_to_drsm,
                           perm_upload_firmware,
                           perm_manage_own_company
                       }: ExtUser): boolean => {
        return !perm_manage_own_company &&
                !perm_god &&
                !perm_use_web &&
                !perm_can_assign_perms &&
                !perm_connect_to_drsm &&
                !perm_upload_firmware
    }

    const onResetEmailClicked = (uuid: ExtUser) => {
        setSelectedUser(uuid)
        setOpenPasswordDialog(true)
    }

    const columns = React.useMemo<GridColDef[]>(() => [
        {
            field     : "name", headerName: "Imię i Nazwisko", flex: 1,
            renderCell: params => <p>{ params.row.first_name } { params.row.last_name } { userIsNew(
                    params.row as ExtUser) ? "(Nieaktywny)" : null } </p>,
            sortable  : false, editable: false, filterable: false
        },
        { field: "email", headerName: "E-Mail", flex: 1, sortable: false, editable: false, filterable: false },
        {
            field     : "company",
            headerName: "Firma",
            flex      : 1,
            renderCell: (params => <p>{ params.row?.company?.name } { params.row?.contractor?.name ?? "" } </p>),
            sortable  : false,
            editable  : false,
            filterable: false
        },
        {
            field     : "registration",
            headerName: "Data rejestracji",
            flex      : 1,
            sortable  : false,
            editable  : false,
            filterable: false,
            renderCell: (params) => <p> { new Date(params.row.registration_time.seconds * 1000).toLocaleString(
                    "pl") } </p>
        },
        {
            headerName  : "Akcje",
            field       : "actions",
            width       : 270,
            renderCell  : (params => {
                return <ButtonGroup color="primary">
                    <Button onClick={ () => onResetEmailClicked(params.row as ExtUser) }> Reset hasła </Button>
                    <Button onClick={ () => history.push(`/users/${ params.id }`) }> Szczegóły </Button>
                </ButtonGroup>
            }), sortable: false, editable: false, filterable: false
        }
    ], [history]);

    useEffect(() => {
        const createUser = async (userData: firebase.firestore.DocumentData) => {
            const company = await KompanyKesz.get(userData["company"]["id"])
            const user = userData as ExtUser
            user.company = company!
            if (user.contractor !== null) user.contractor = (await user.contractor.get()).data()
            user.id = user.user_id
            console.log([ company, user ])
            setUsers((current_users) => [ user, ...current_users ])
        }

        const loadUsers = async () => {
            let users_query = UsersQuery(auth)

            const promises: Promise<void>[] = []

            for (const query of users_query) {
                const users_snapshot = await query.get()
                users_snapshot.forEach((doc) => {
                    const data = createUser(doc.data() as ExtUser)
                    promises.push(data)
                })
            }

            await Promise.all(promises)
            setIsLoading(false)
        }

        setUsers([])
        loadUsers();
    }, [auth])


    return (
            <div style={ { width: "100%", height: 700 } }>
                <TextField fullWidth style={ { marginBottom: ".5em" } } variant={ "outlined" }
                           helperText={ "Filtrowanie po imieniu, nazwisku lub adresie email. Jeżeli chcesz wyświetlić" +
                                   " nieaktywnych użytkowników wpisz 'nieaktywny'" }
                           onChange={ e => setFilterValue(e.target.value.toLowerCase()) }/>
                <ResetPasswordDialog open={ openPasswordDialog }
                                     setOpen={ setOpenPasswordDialog }
                                     selectedUid={ selectedUser! }/>
                <DataGrid disableColumnMenu
                          hideFooterRowCount
                          hideFooterSelectedRowCount
                          key={ 1 }
                          loading={ isLoading }
                          rows={ users.filter((u): boolean => {
                              if (filterValue === "") return true
                              if (filterValue === "nieaktywny") return userIsNew(u)

                              return u.first_name.toLowerCase().includes(filterValue)
                                      || u.last_name.toLowerCase().includes(filterValue)
                                      || u.email.toLowerCase().includes(filterValue)
                          }) }
                          columns={ columns }/>
            </div>
    )
}

type DialogType = {
    open: boolean,
    setOpen: (value: boolean) => void,
    selectedUid: ExtUser
}

export function ResetPasswordDialog({ open, setOpen, selectedUid }: DialogType) {
    const [ secondaryEmail, setSecondaryEmail ] = useState("")
    const [ isSending, setIsSending ] = useState(false)

    if (!selectedUid) return <></>

    const sendPasswordReset = () => {
        let payload: Record<string, string> = { userEmail: selectedUid.email }
        if (secondaryEmail !== "")
            payload = { ...payload, secondaryEmail }
        setIsSending(true)
        axios.post(firebasePassReset, payload).then((_) => {
            setIsSending(false)
            setOpen(false)
        })
    }

    return <Dialog open={ open } onClose={ () => setOpen(false) } fullWidth>
        <DialogTitle>
            Reset hasła dla użytkownika { selectedUid.first_name } { selectedUid.last_name }
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Możesz wybrać żeby dodatkowo wysłać wiadomość resetującą hasło na inny adres email niż podany przy
                rejestracji.
                Jeżeli chcesz wysłać tylko na standardowy zostaw pole puste.
            </DialogContentText>
            <DialogContentText>
                Adres email podany przy rejestracji { selectedUid.email }
            </DialogContentText>
            <TextField fullWidth onChange={ e => setSecondaryEmail(e.target.value) }/>
            <Button fullWidth variant={ "contained" } color={ "primary" } style={ { marginTop: "1em" } }
                    onClick={ () => sendPasswordReset() }>
                Wyślij wiadomość resetującą
            </Button>
            { isSending ? <LinearProgress style={ { marginTop: ".5em" } }/> : null }
        </DialogContent>
    </Dialog>
}


