import * as React from 'react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { Device } from '../ble/Device';
import { useCharValue } from '../ble/hooks';
import { DrsmServices, StatusService, StatusSerialCharacteristic, StatusFwverMbCharacteristic, StatusCurrentDatetimeCharacteristic, PermissionsValue } from '../constants/bleDefs';
import { DrsmLog } from './DrsmLog';

export interface DrsmCardProps
{
    device: Device<typeof DrsmServices>,
    permissions: PermissionsValue,
    onDisconnectClick?: () => void,
}

interface LoadableValueProps
{
    value?: string;
}

function LoadableValue({ value }: LoadableValueProps)
{
    if (value)
        return (<>{value}</>);
    else
        return (<Typography variant='caption' color='textSecondary'>Wczytywanie</Typography>);
}

const useStyles = makeStyles(theme => ({
    card: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    }
}));

export function DrsmCard({ device, permissions, onDisconnectClick }: DrsmCardProps)
{
    const status = device.getService<StatusService>('status')!;
    const [ serial ] = useCharValue(status.getCharacteristic<StatusSerialCharacteristic>('serial'), false);
    const [ version ] = useCharValue(status.getCharacteristic<StatusFwverMbCharacteristic>('fwverMb'), false);
    const [ time ] = useCharValue(status.getCharacteristic<StatusCurrentDatetimeCharacteristic>('currentDatetime'), true);
    const classes = useStyles();

    let statusLine = 'Łączenie';
    if (permissions.read_status && ! permissions.config_zone)
    {
        statusLine = 'Logowanie';
    }
    else
    {
        statusLine = 'Gotowy';
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <Typography variant='h5' color='textPrimary'>Połączono z DRSM #<LoadableValue value={serial} /> v<LoadableValue value={version?.split('-')[0]} /></Typography>
                { time && <Typography variant='body1'>Aktualny czas urządzenia: {time.toLocaleString()}</Typography> }
                <Typography variant='body1'>{statusLine}</Typography>
                { device && <DrsmLog serial={serial || ""} device={device} permissions={permissions} onDisconnectClick={onDisconnectClick} />}
            </CardContent>
        </Card>
    )
}