// @flow
import * as React                                        from 'react'
import { useRef, useState }                              from 'react'
import * as yup                                          from "yup"
import { Button, Card, Snackbar, TextField, Typography } from "@material-ui/core"
import { Field, Form, Formik, FormikValues }             from 'formik'
import { makeStyles }                                    from "@material-ui/core/styles"
import axios                                             from "axios"
import { firebaseFunctionsUrl }                          from "../constants/urls"
import { useHistory }                                    from "react-router-dom"
import { Alert }                                         from "@material-ui/lab"

type Props = {}

const initValues = {
    first_name: "",
    last_name : "",
    email     : "",
    password  : "",
    secret_key: ""
}

const validationSchema = yup.object({
    first_name: yup.string().required().max(100),
    last_name : yup.string().required().max(100),
    email     : yup.string().required().email().max(100),
    password  : yup.string().required().max(100),
    secret_key: yup.string().required().max(100)
})

const useStyle = makeStyles({
    main       : {
        padding: "2em"
    },
    form       : {
        display      : "flex",
        flexDirection: "column",
        width        : "max(40vw, 250px)"
    },
    textInput  : {
        width : "100%",
        margin: "10px 1em"
    },
    loginButton: {
        width          : "100%",
        backgroundColor: "forestgreen",
        color          : "white"
    }

})

export function Register(_: Props) {
    const classes = useStyle()
    const history = useHistory()
    const msgType = useRef<number>(- 1)

    const [ snackOpen, setSnackOpen ] = useState(false)

    const submitForm = async (data: FormikValues) => {
        try {
            await axios.post(firebaseFunctionsUrl, {
                first_name    : data.first_name,
                last_name     : data.last_name,
                email         : data.email,
                password      : data.password,
                validation_key: data.secret_key
            })
            history.push("/login")
        } catch (e) {
            if (e.response.status === 401) {
                msgType.current = 401
                setSnackOpen(true)
            } else if (e.response.status === 400) {
                msgType.current = 400
                setSnackOpen(true)
            }
        }
    }

    const onSnackClose = (_?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return
        setSnackOpen(false)
    }

    return (
            <Card className={ classes.main }>
                <Typography variant={ "h4" }>
                    Zarejestruj się
                </Typography>

                <Snackbar anchorOrigin={ { vertical: "top", horizontal: "left" } }
                          open={ snackOpen }
                          onClose={ onSnackClose }
                          autoHideDuration={ 3000 }>
                    <Alert severity={ "error" } onClose={ onSnackClose }>
                        { msgType.current === 400 ? "Konto o podanym adresie email już istnieje" : "Nieprawidłowy kod" +
                                " uwierzytelniający" }
                    </Alert>
                </Snackbar>

                <Formik initialValues={ initValues } validationSchema={ validationSchema } onSubmit={ submitForm }>
                    { ({ errors, touched, isValid }) => (
                            <Form className={ classes.form }>
                                <Field label={ "Imię" }
                                       error={ !!errors.first_name && touched.first_name }
                                       helperText={ errors.first_name && touched.first_name ? errors.first_name : "" }
                                       as={ TextField }
                                       className={ classes.textInput }
                                       name={ "first_name" }/>

                                <Field label={ "Nazwisko" }
                                       error={ !!errors.last_name && touched.last_name }
                                       helperText={ errors.last_name && touched.last_name ? errors.last_name : "" }
                                       as={ TextField }
                                       className={ classes.textInput }
                                       name={ "last_name" }/>

                                <Field label={ "Email" }
                                       error={ !!errors.email && touched.email }
                                       helperText={ errors.email && touched.email ? errors.email : "" }
                                       as={ TextField }
                                       className={ classes.textInput }
                                       name={ "email" }/>

                                <Field label={ "Hasło" }
                                       error={ !!errors.password && touched.password }
                                       helperText={ errors.password && touched.password ? errors.password : "" }
                                       as={ TextField }
                                       type={ "password" }
                                       className={ classes.textInput }
                                       name={ "password" }/>

                                <Field label={ "Kod uprawniający" }
                                       error={ !!errors.secret_key && touched.secret_key }
                                       helperText={ errors.secret_key && touched.secret_key ? errors.secret_key : "" }
                                       as={ TextField }
                                       type={ "password" }
                                       className={ classes.textInput }
                                       name={ "secret_key" }/>

                                <Button disabled={ !isValid }
                                        type={ "submit" }
                                        className={ classes.loginButton }>
                                    Zarejestruj się
                                </Button>
                            </Form>
                    ) }
                </Formik>
            </Card>
    )
}
