import { Characteristic, CharacteristicDefinition, CharacteristicTypeFromDef } from "./Characteristic";
import { Device } from "./Device";

// type DecoderFunction<T> = (value: DataView) => Promise<T>;
// type EncoderFunction<T> = (value: T) => Promise<ArrayBuffer>;
// type DecodedType<D, T extends DecoderFunction<D> | undefined> = T extends DecoderFunction<D> ? D : ArrayBuffer;
// type EncodedType<E, T extends EncoderFunction<E> | undefined> = T extends EncoderFunction<E> ? E : ArrayBuffer;

export type CharsDef<T> = {
    [C in keyof T]: T[C] extends CharacteristicDefinition<infer D, infer E> ? CharacteristicDefinition<D, E> : never;
}

// export interface ServiceDef<C extends { characteristics: CharsDef<C['characteristics']> }>
// {
//     uuid: string,
//     characteristics: CharsDef<C['characteristics']>,
// }

export type ServiceDefBase<C> = {
    uuid: string,
    characteristics: C
};

export type ServiceDef<T> = T extends ServiceDefBase<CharsDef<infer C>> ? ServiceDefBase<CharsDef<C>> : never;

export class Service<T>
{
    private _def: ServiceDef<T>;
    private _service: BluetoothRemoteGATTService | undefined;
    private _characteristics: Map<string, Characteristic<unknown>>;

    constructor(definition: ServiceDef<T>)
    {
        this._def = definition;
        this._characteristics = new Map();
    }

    public async init<T>(device: Device<T>)
    {
        await device.runExclusive(async () => {
            this._service = await device.gatt.getPrimaryService(this._def!.uuid);
            for(const key in this._def!.characteristics)
            {
                const def = (this._def!.characteristics as any)[key];
                const ch = await this._service.getCharacteristic(def.uuid);
                this._characteristics.set(key, new Characteristic(device, ch, def));
            }
        });
    }

    public getCharacteristic<Def = never>(key: keyof ServiceDef<T>['characteristics'] & string)
    {
        if (! this._characteristics.has(key))
            throw new Error(`Unknown characteristic ${key} in service ${this._def.uuid}`);

        return this._characteristics.get(key)! as CharacteristicTypeFromDef<Def extends never ? ServiceDef<T>['characteristics'][typeof key] : Def>;
    }
}