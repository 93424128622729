import { Device } from "./Device";

export interface CharacteristicDefinition<D = DataView, E = D> {
    uuid: string;
    decoder: (value: DataView) => Promise<D>;
    encoder: (value: E) => Promise<ArrayBuffer>;
}
export type CharacteristicValueType<T> = T extends void ? ArrayBuffer : T;
export type CharacteristicNotificationHandler<T> = (data: CharacteristicValueType<T>) => void | Promise<void>;
export type CharacteristicTypeFromDef<Def> = Def extends CharacteristicDefinition<infer D, infer E> ? Characteristic<D, E> : never;

export class Characteristic<D = DataView, E = D> {
    private readonly _char: BluetoothRemoteGATTCharacteristic;
    private readonly _definition: CharacteristicDefinition<D, E>;
    private readonly _device: Device<any>;
    private _notify_counter: number;

    public constructor(device: Device<any>, char: BluetoothRemoteGATTCharacteristic, definition: CharacteristicDefinition<D, E>) {
        this._char = char;
        this._definition = definition;
        this._device = device;
        this._notify_counter = 0;
    }

    public async read(): Promise<CharacteristicValueType<D>> {
        console.log(`[DBG] Reading from ${this._definition.uuid}`);
        let value :DataView = undefined as any;
        await this._device.runExclusive(async () => { value = await this._char.readValue(); });

        try {
            console.log(`[DBG] Got ${[...new Uint8Array(value.buffer)].map(v => v.toString(16)).join(', ')}`);
        } catch (e) {
            console.log(`[DBG] Got <data>`, value, e);
        }

        return this.decodeValue(value);
    }

    public async write(value: CharacteristicValueType<E>) {
        const buffer: ArrayBuffer = 
            (this._definition.encoder)
            ? await this._definition.encoder(value as E) as ArrayBuffer
            : value as ArrayBuffer;

        const writeValue = this._char.writeValueWithResponse || this._char.writeValue;
        try {
            console.log(`[DBG] Writing ${[...new Uint8Array(buffer)].map(v => v.toString(16)).join(', ')} to ${this._definition.uuid}`);
        } catch (e) {
            console.log(`[DBG] Writing <data> to ${this._definition.uuid}`, value, e);
        }
        await this._device.runExclusive(async () => await writeValue.apply(this._char, [buffer]));
    }

    async enableNotifications(on_notification: CharacteristicNotificationHandler<D>) {
        await this._device.runExclusive(async () => {
            this._notify_counter++;
            if (this._notify_counter === 1)
                await this._char.startNotifications();
        });

        const handler = async (event: Event) => {
            try 
            {
                const value: DataView = (event.target as any).value;
                const decoded = await this.decodeValue(value);
                console.log(`[DBG] Got notification for ${this._definition.uuid}`, value, decoded);
                await on_notification(decoded);
            }
            catch(e)
            {
                console.error(`Error in notification (${this._definition.uuid}) handler`, e);
            }
        };

        this._char.addEventListener('characteristicvaluechanged', handler);

        return (async () => {
            this._char.removeEventListener('characteristicvaluechanged', handler);
            
            await this._device.runExclusive(async () => {
                this._notify_counter--;
                if (this._notify_counter === 0)
                    await this._char.stopNotifications();
            });
        });
    }

    private async decodeValue(value: DataView): Promise<CharacteristicValueType<D>>
    {
        // TODO: not sure how to correctly handle this. 
        // We know that when the decode is set the return type is D, but TS doesn't seem to know that
        if (this._definition.decoder)
            return (await this._definition.decoder(value)) as any; 
        else
            return value.buffer as CharacteristicValueType<D>;
    }
};
