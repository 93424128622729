// @flow
import * as React               from 'react'
import { useEffect, useState }  from 'react'
import { DataGrid, GridColDef } from "@material-ui/data-grid"
import { Zone }                 from "../models/zone"
import firebase                 from "firebase"
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField,
    Typography
}                               from "@material-ui/core"
import { makeStyles }           from "@material-ui/core/styles"
import { Alert }                from "@material-ui/lab"

type Props = {};

const useStyles = makeStyles(theme => ({
    toolbar_wrapper: {
        display     : 'flex',
        marginBottom: "1.5em"
    },
    toolbar_stuffer: {
        display : 'inline-block',
        flexGrow: 1,
    },
    dialog_row     : {
        display                           : 'flex',
        '& .MuiTextField-root'            : {
            margin  : theme.spacing(1),
            minWidth: '9ch',
            flexGrow: 1,
        },
        '& .MuiTextField-root:first-child': {
            marginLeft: 0,
        },
        '& .MuiTextField-root:last-child' : {
            marginRight: 0,
        }
    },
    dialog_spacer  : {
        width : '100%',
        height: '1rem',
    },
}))

interface RelayData {
    active: boolean;
    telegram_type: number;
    command_id: number;
    auto_active: boolean;
    auto_mode: string;
    auto_monitors_radio: boolean;
}

interface ParsedData {
    address_1: number;
    address_2: number;
    relays: RelayData[];
    location: {
        latitude: number;
        longitude: number;
    };
    seconds_to_auto: number;
    min_signal_level: number;
    max_advance_to_auto: number;
    max_delay_to_auto: number;
    min_force_frames: number;
    max_force_time: number;
}

interface DialogData {
    parsedData?: ParsedData
    selectedRelay: number

    name: string
    address_1: number
    address_2: number
    command_id: number
    telegram_type: number
    seconds_to_auto: number
    auto_monitors_radio: boolean
    max_advance_to_auto: number
    max_delay_to_auto: number
    max_force_time: number
    min_force_frames: number
    min_signal_level: number
    latitude: number
    longitude: number
    turn_on_delay: number
    turn_off_delay: number

    auto_night_off: boolean
    auto_night_off_start: number
    auto_night_off_end: number
    init_auto_night_off_start: number
    init_auto_night_off_end: number
}

const initialDialogData: DialogData = {
    selectedRelay: 0,

    name               : "",
    address_1          : 0,
    address_2          : 0,
    command_id         : 0,
    telegram_type      : 0,
    seconds_to_auto    : 0,
    auto_monitors_radio: false,
    max_advance_to_auto: 0,
    max_delay_to_auto  : 0,
    max_force_time     : 0,
    min_force_frames   : 0,
    min_signal_level   : 0,
    latitude           : 0,
    longitude          : 0,
    turn_on_delay      : 0,
    turn_off_delay     : 0,

    auto_night_off      : false,
    auto_night_off_start: 0,
    auto_night_off_end  : 0,
    init_auto_night_off_start: 0,
    init_auto_night_off_end  : 0
}

enum DialogOpMode {
    Create, Import, Edit
}

export function Zones(_: Props) {
    const [ zones, setZones ] = useState<Zone[]>([])
    const [ loadingError, setLoadingError ] = useState<any>(undefined)
    const [ isLoading, setIsLoading ] = useState(true)
    const styles = useStyles()
    const [ dialogData, setDialogData ] = useState<DialogData | null>(null)
    const [ dialogOpMode, setDialogOpMode ] = useState(DialogOpMode.Create)
    const [ duplicateZone, setDuplicateZone ] = useState<Zone | undefined>()
    const [ filterValue, setFilterValue ] = useState("")


    const columns: GridColDef[] = [
        { headerName: "Nazwa", field: "name", flex: 1, sortable: false, editable: false, filterable: false },
        { headerName: "ID Strefy", field: "zone_id", flex: 1, sortable: false, editable: false, filterable: false },
        {
            field     : "action",
            headerName: "Działania",
            flex      : 1, sortable: false, editable: false, filterable: false,
            renderCell: (params => {
                return (<>
                    <Button
                            onClick={ () => {
                                setDialogOpMode(DialogOpMode.Edit)
                                setDialogData({
                                    selectedRelay       : - 1,
                                    name                : params.row.name,
                                    address_1           : params.row.address_1,
                                    address_2           : params.row.address_2,
                                    command_id          : params.row.command_id,
                                    telegram_type       : params.row.telegram_type,
                                    seconds_to_auto     : params.row.seconds_to_auto,
                                    auto_monitors_radio : params.row.auto_monitors_radio,
                                    max_advance_to_auto : params.row.max_advance_to_auto,
                                    max_delay_to_auto   : params.row.max_delay_to_auto,
                                    max_force_time      : params.row.max_force_time,
                                    min_force_frames    : params.row.min_force_frames,
                                    min_signal_level    : params.row.min_signal_level,
                                    latitude            : params.row.location.latitude,
                                    longitude           : params.row.location.longitude,
                                    turn_on_delay       : params.row.turn_on_delay,
                                    turn_off_delay      : params.row.turn_off_delay,
                                    auto_night_off      : params.row.auto_night_off || false,
                                    auto_night_off_start: params.row.auto_night_off_start || 0,
                                    auto_night_off_end  : params.row.auto_night_off_end || 0,
                                    init_auto_night_off_start: params.row.auto_night_off_start || 0,
                                    init_auto_night_off_end  : params.row.auto_night_off_end || 0

                                })
                            } }
                            variant="outlined"
                            color="primary"
                    >
                        Edytuj
                    </Button>
                </>)
            })
        },
    ]


    const loadErrors = async () => {
        const users_snapshot = await firebase
                .firestore()
                .collection("zones")
                .get()

        const localZones: Zone[] = []
        users_snapshot.forEach((doc) => {
            const zone = doc.data() as Zone
            zone.id = doc.id
            zone.zone_id = `${ zone.address_1 }:${ zone.address_2 }/${ zone.command_id }/${ zone.telegram_type }`
            localZones.push(zone)
        })
        setZones(localZones)
        setIsLoading(false)
    }

    useEffect(() => {
        if (!dialogData) {
            loadErrors().catch(err => {
                console.log('Cannot load data!', err)
                setLoadingError(true)
            })
        } else {
            const dialogId = `${ dialogData.address_1 }N${ dialogData.address_2 }N${ dialogData.command_id }N${ dialogData.telegram_type }`
            firebase.firestore().doc(`/zones/${ dialogId }`).get().then(zone => {
                setDuplicateZone(zone.data() as Zone | undefined)
            })
        }
    }, [ dialogData ])

    const getElementByTag = (element: Element | Document, tagName: string) => {
        const elements = element.getElementsByTagName(tagName)
        if (elements.length === 0)
            throw new Error(`Nie znaleziono tagu '${ tagName }'`)
        else if (elements.length > 1)
            throw new Error(`Znaleziono więcej niż jeden tag '${ tagName }'`)

        return elements[0]
    }

    const getNumber = (element: Element | Document, tagName: string) => Number(
            getElementByTag(element, tagName).textContent)

    const parseRelay = (relay: Element) => {
        const active = getNumber(relay, 'aktywny') !== 0
        const telegram_type = getNumber(relay, 'rodzaj-telegramu')
        const command_id = getNumber(relay, 'nr-polecenia')
        const auto_active = getNumber(relay, 'aktywny') !== 0
        const auto_mode = getElementByTag(relay, 'nazwa-trybu-autonom').textContent
        const auto_monitors_radio = getNumber(relay, 'tryb-autonomiczny-nadzoruje-radio') !== 0

        return {
            active,
            telegram_type,
            command_id,
            auto_active,
            auto_mode,
            auto_monitors_radio,
        }
    }

    const parseFile = async (file: File) => {
        const text = await file.text()
        const parser = new DOMParser()
        const doc = parser.parseFromString(text, 'text/xml')

        const el_address = getElementByTag(doc, 'adres')
        const el_geo = getElementByTag(doc, 'geo')

        const address_1 = getNumber(el_address, 'Adres1')
        const address_2 = getNumber(el_address, 'Adres2')
        const relays = [
            parseRelay(getElementByTag(doc, 'przekaznik1')),
            parseRelay(getElementByTag(doc, 'przekaznik2')),
            parseRelay(getElementByTag(doc, 'przekaznik3')),
        ]

        const latitude = getNumber(el_geo, 'szer-st') + (getNumber(el_geo, 'szer-min') / 60)
        const longitude = getNumber(el_geo, 'dl-st') + (getNumber(el_geo, 'dl-min') / 60)

        // Ignore corrections
        // Ignore <osw-do-polnocy>

        const seconds_to_auto = getNumber(doc, 'sekundy-do-autonom')
        const min_signal_level = getNumber(doc, 'poziom-graniczny-radio')
        const max_advance_to_auto = getNumber(doc, 'max-przysp-do-autonom') * 60
        const max_delay_to_auto = getNumber(doc, 'max-opozn-po-autonom') * 60
        const min_force_frames = getNumber(doc, 'min-ilosc-ramek-wymuszenia')
        const max_force_time = getNumber(doc, 'max-czas-wymuszenia')

        const cfg = {
            address_1,
            address_2,
            relays,
            location: { latitude, longitude },
            seconds_to_auto,
            min_signal_level,
            max_advance_to_auto,
            max_delay_to_auto,
            min_force_frames,
            max_force_time,
        } as ParsedData
        console.log(cfg)
        const the_relay = relays.find(r => r.auto_mode === 'oświetlenie całodobowe' && r.active)
        if (!the_relay) {
            alert('Nie znaleziono kompatybilnego przekaźnika')
            return
        }
        const relay_id = relays.indexOf(the_relay)
        setDialogData({
            parsedData   : cfg,
            name         : '',
            selectedRelay: relay_id,

            address_1           : cfg.address_1,
            address_2           : cfg.address_2,
            command_id          : the_relay.command_id,
            telegram_type       : the_relay.telegram_type,
            seconds_to_auto     : 600,
            auto_monitors_radio : true,
            max_advance_to_auto : 45 * 60,
            max_delay_to_auto   : 30 * 60,
            max_force_time      : 300,
            min_force_frames    : 2,
            min_signal_level    : 0.180,
            latitude            : cfg.location.latitude,
            longitude           : cfg.location.longitude,
            turn_on_delay       : 12 * 60,
            turn_off_delay      : 5 * 60,
            auto_night_off      : false,
            auto_night_off_start: 0,
            auto_night_off_end  : 0,
            init_auto_night_off_start: 0,
            init_auto_night_off_end  : 0
        })
    }

    const importFile = () => {
        setDialogOpMode(DialogOpMode.Import)
        const el = document.createElement('input')
        el.setAttribute('type', 'file')
        el.setAttribute('accept', '.rps2')
        el.setAttribute('style', 'display: none')
        const body = document.getElementsByTagName('body')[0]
        body.appendChild(el)
        el.click()
        el.addEventListener('change', _ => {
            console.log(el.files)
            body.removeChild(el)
            if (el.files?.length)
                parseFile(el.files?.item(0) as File).catch(err => console.log(err))
        })
    }

    const addZoneFromFile = async () => {
        console.log(dialogData)
        if (!dialogData)
            return
        try {
            await firebase.firestore().collection('zones')
                          .doc(`${ dialogData.address_1 }N${ dialogData.address_2 }N${ dialogData.command_id }N${ dialogData.telegram_type }`)
                          .set({
                              name               : dialogData.name,
                              address_1          : dialogData.address_1,
                              address_2          : dialogData.address_2,
                              command_id         : dialogData.command_id,
                              telegram_type      : dialogData.telegram_type,
                              seconds_to_auto    : dialogData.seconds_to_auto,
                              auto_monitors_radio: dialogData.auto_monitors_radio,
                              max_advance_to_auto: dialogData.max_advance_to_auto,
                              max_delay_to_auto  : dialogData.max_delay_to_auto,
                              max_force_time     : dialogData.max_force_time,
                              min_force_frames   : dialogData.min_force_frames,
                              min_signal_level   : dialogData.min_signal_level,
                              turn_on_delay      : dialogData.turn_on_delay,
                              turn_off_delay     : dialogData.turn_off_delay,
                              auto_night_off      : dialogData.auto_night_off,
                              auto_night_off_start: dialogData.auto_night_off_start,
                              auto_night_off_end  : dialogData.auto_night_off_end,
                              location           : new firebase.firestore.GeoPoint(dialogData.latitude,
                                      dialogData.longitude),
                          })
            setDialogData(null)
        } catch (e) {
            console.log(e)
            alert('Błąd podczas zapisywania danych')
        }
    }

    const titleFromOpMode = () => {
        if (dialogOpMode === DialogOpMode.Create) return "Tworzenie nowej strefy"
        if (dialogOpMode === DialogOpMode.Edit) return "Edycja strefy"
        if (dialogOpMode === DialogOpMode.Import) return "Import strefy z pliku"
        return "Nierozpoznany tryb operacji"
    }

    const buttonTextFromOpMode = () => {
        if (dialogOpMode === DialogOpMode.Create || dialogOpMode === DialogOpMode.Import) return "Dodaj strefę"
        return "Zapisz strefę"
    }

    return (<>
        <Dialog fullScreen open={ dialogData !== null }>
            <DialogTitle>{ titleFromOpMode() }</DialogTitle>
            <DialogContent>
                { dialogOpMode !== DialogOpMode.Edit ?
                        <DialogContentText>Sprawdź ustawienia strefy i dodaj ją do bazy.</DialogContentText> : null }
                <TextField
                        type="text"
                        label="Nazwa"
                        placeholder="Wpisz nazwę strefy"
                        fullWidth
                        value={ dialogData?.name }
                        onChange={ ev => setDialogData(data => (data ? { ...data, name: ev.target.value } : null)) }
                />
                <div className={ styles.dialog_spacer }/>
                <Typography variant="subtitle1">Tryb radiowy</Typography>
                <div className={ styles.dialog_row }>
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 0,
                                    max: 600,
                                }
                            } }
                            label="Min. poziom sygnału [mV]"
                            value={ dialogData ? (1000 * dialogData.min_signal_level) : null }
                            onChange={ ev => setDialogData(data => (data ? {
                                ...data,
                                min_signal_level: parseInt(ev.target.value, 10) / 1000
                            } : null)) }
                    />
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 120,
                                    max: 100000
                                }
                            } }
                            label="Czas do auto. [s]"
                            value={ dialogData?.seconds_to_auto }
                            onChange={ ev => setDialogData(data => (data ? {
                                ...data,
                                seconds_to_auto: parseInt(ev.target.value, 10)
                            } : null)) }
                    />

                </div>
                <div className={ styles.dialog_row }>
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 0,
                                    max: 254
                                }
                            } }
                            disabled={ dialogOpMode === DialogOpMode.Edit }
                            label="Adres 1"
                            value={ dialogData?.address_1 }
                            onChange={ ev => setDialogData(
                                    data => (data ? { ...data, address_1: parseInt(ev.target.value, 10) } : null)) }
                    />
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 0,
                                    max: 254
                                }
                            } }
                            disabled={ dialogOpMode === DialogOpMode.Edit }
                            label="Adres 2"
                            value={ dialogData?.address_2 }
                            onChange={ ev => setDialogData(
                                    data => (data ? { ...data, address_2: parseInt(ev.target.value, 10) } : null)) }
                    />
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 0,
                                    max: 32767
                                }
                            } }
                            disabled={ dialogOpMode === DialogOpMode.Edit }
                            label="Komenda"
                            value={ dialogData?.command_id }
                            onChange={ ev => setDialogData(
                                    data => (data ? { ...data, command_id: parseInt(ev.target.value, 10) } : null)) }
                    />
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 0,
                                    max: 7
                                }
                            } }
                            disabled={ dialogOpMode === DialogOpMode.Edit }
                            label="Telegram"
                            value={ dialogData?.telegram_type }
                            onChange={ ev => setDialogData(
                                    data => (data ? { ...data, telegram_type: parseInt(ev.target.value, 10) } : null)) }
                    />
                </div>
                <FormControlLabel control={
                    <Switch
                            checked={ !!dialogData?.auto_monitors_radio }
                            onChange={ ev => setDialogData(
                                    data => (data ? { ...data, auto_monitors_radio: ev.target.checked } : null)) }
                    />
                } label="Nadzorowany przez tryb autonomiczny"/>
                <div className={ styles.dialog_row }>
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 2,
                                    max: 10000
                                }
                            } }
                            label="Maks. czas wymuszenia [min]"
                            disabled={ dialogData?.auto_monitors_radio !== true }
                            value={ dialogData ? (dialogData.max_force_time / 60) : null }
                            onChange={ ev => setDialogData(data => (data ? {
                                ...data,
                                min_signal_level: parseInt(ev.target.value, 10) * 60
                            } : null)) }
                    />
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 1,
                                    max: 5
                                }
                            } }
                            label="Ilość ramek wymuszenia"
                            disabled={ dialogData?.auto_monitors_radio !== true }
                            value={ dialogData?.min_force_frames }
                            onChange={ ev => setDialogData(data => (data ? {
                                ...data,
                                min_force_frames: parseInt(ev.target.value, 10)
                            } : null)) }
                    />
                </div>
                <div className={ styles.dialog_row }>
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 1,
                                }
                            } }
                            label="Maks. przysp. do auto. [min]"
                            disabled={ dialogData?.auto_monitors_radio !== true }
                            value={ dialogData ? (dialogData.max_advance_to_auto / 60) : null }
                            onChange={ ev => setDialogData(data => (data ? {
                                ...data,
                                max_advance_to_auto: parseInt(ev.target.value, 10) * 60
                            } : null)) }
                    />
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: 1,
                                }
                            } }
                            label="Maks. opóźn. do auto. [min]"
                            disabled={ dialogData?.auto_monitors_radio !== true }
                            value={ dialogData ? (dialogData.max_delay_to_auto / 60) : null }
                            onChange={ ev => setDialogData(data => (data ? {
                                ...data,
                                max_delay_to_auto: parseInt(ev.target.value, 10) * 60
                            } : null)) }
                    />
                </div>
                <div className={ styles.dialog_spacer }/>
                <Typography variant="subtitle1">Tryb autonomiczny</Typography>
                <div className={ styles.dialog_row }>
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min : - 180,
                                    max : 180,
                                    step: 0.0001
                                }
                            } }
                            label="Dł. geograficzna"
                            value={ dialogData?.latitude }
                            onChange={ ev => setDialogData(
                                    data => (data ? { ...data, latitude: parseFloat(ev.target.value) } : null)) }
                    />
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min : 0,
                                    max : 90,
                                    step: 0.0001
                                }
                            } }
                            label="Szer. geograficzna"
                            value={ dialogData?.longitude }
                            onChange={ ev => setDialogData(
                                    data => (data ? { ...data, longitude: parseFloat(ev.target.value) } : null)) }
                    />
                </div>
                <div className={ styles.dialog_row }>
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: - 99,
                                    max: 99
                                }
                            } }
                            label="Opóźnienie załączenia [min]"
                            value={ dialogData ? (dialogData.turn_on_delay / 60) : null }
                            onChange={ ev => setDialogData(data => (data ? {
                                ...data,
                                turn_on_delay: parseInt(ev.target.value, 10) * 60
                            } : null)) }
                    />
                    <TextField
                            type="number"
                            InputProps={ {
                                inputProps: {
                                    min: - 99,
                                    max: 99
                                }
                            } }
                            label="Opóźnienie wyłączenia [min]"
                            value={ dialogData ? (dialogData.turn_off_delay / 60) : null }
                            onChange={ ev => setDialogData(data => (data ? {
                                ...data,
                                turn_off_delay: parseInt(ev.target.value, 10) * 60
                            } : null)) }
                    />
                </div>

                <FormControlLabel control={
                    <Switch
                            checked={ !!dialogData?.auto_night_off }
                            onChange={ ev => setDialogData(
                                    data => (data ? { ...data, auto_night_off: ev.target.checked } : null)) }
                    />
                } label="Oświetlenie do północy [aktywne/nieaktywne]"/>
                <div className={ styles.dialog_row }>
                    <ZoneTimeField dialogData={ dialogData }
                                   initialValue={dialogData?.init_auto_night_off_start || 0}
                                   helper={ "Godzina rozpoczęcia przerwy nocnej" }
                                   onChange={ (val) => setDialogData(data => (data ? {
                                       ...data,
                                       auto_night_off_start: val
                                   } : null)) }
                                   setDialogData={ setDialogData }/>
                    <ZoneTimeField dialogData={ dialogData }
                                   initialValue={(dialogData?.init_auto_night_off_end || (24*60)) - (24*60)}
                                   helper={ "Godzina zakończenia przerwy nocnej" }
                                   onChange={ (val) => setDialogData(data => (data ? {
                                       ...data,
                                       auto_night_off_end: val + (24 * 60)
                                   } : null)) }
                                   setDialogData={ setDialogData }/>

                </div>

                {
                    duplicateZone && dialogOpMode !== DialogOpMode.Edit ?
                            <Alert severity={ "error" } style={ { margin: "1em 0" } }> Strefa o podanych danych
                                adresowych już istnieje,
                                czy na pewno chcesz ją nadpisać?
                                ({ duplicateZone?.name }) </Alert> : null
                }


            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ () => setDialogData(null) }>Anuluj</Button>
                <Button color="primary" onClick={ addZoneFromFile }>{ buttonTextFromOpMode() }</Button>
            </DialogActions>
        </Dialog>

        <TextField fullWidth style={ { marginBottom: ".5em" } } variant={ "outlined" }
                   helperText={ "Filtrowanie po nazwie oraz ID." }
                   onChange={ e => setFilterValue(e.target.value.toLowerCase()) }/>

        <div className={ styles.toolbar_wrapper }>
            <div className={ styles.toolbar_stuffer }/>
            <ButtonGroup color="primary" variant="contained">
                <Button onClick={ () => {
                    setDialogData(initialDialogData)
                    setDialogOpMode(DialogOpMode.Create)
                } }>Dodaj</Button>
                <Button onClick={ importFile }>Importuj</Button>
            </ButtonGroup>
        </div>
        <div style={ { width: "100%", height: 650 } }>
            <DataGrid hideFooterSelectedRowCount
                      disableColumnMenu
                      hideFooterRowCount
                      key={ 1 }
                      loading={ isLoading }
                      error={ loadingError }
                      rows={ zones.filter((zon): boolean => {
                          if (filterValue === "") return true

                          return zon.name.toLowerCase().includes(filterValue) ||
                                  !!zon?.zone_id?.toLowerCase()?.includes(filterValue) ||
                                  !!zon?.id?.toLowerCase()?.includes(filterValue)
                      }) }
                      columns={ columns }/>
        </div>
    </>)
}


type ZoneTimeFieldProps = {
    dialogData: DialogData | null,
    setDialogData: (value: (data: any) => any) => void
    onChange: (value: number) => void,
    helper: string,
    initialValue: number,
}

function ZoneTimeField({ dialogData, initialValue, onChange, helper }: ZoneTimeFieldProps) {
    const formatValue = (val: number) => {
        const hours = Math.floor(val/60);
        const minutes = val % 60;
        return `${("0" + hours.toString()).slice(-2)}:${("0" + minutes.toString()).slice(-2)}`;
    }

    const [ currentValue, setCurrentValue ] = useState("");

    useEffect(() => {
        setCurrentValue(formatValue(initialValue));
    }, [initialValue]);
    
    const _onChange = (ev: any) => {
        setCurrentValue(ev.target.value)
        const parserData = parse(ev.target.value)
        if (parserData) {
            let hour = parseInt(parserData[0], 10)
            let minute = parseInt(parserData[1], 10)
            console.log([ ...parserData, hour, minute ])

            if (hour < 0) hour = 0;
            if (hour >= 24) hour = 23;
            if (minute < 0) minute = 0;
            if (minute >= 60) minute = 59;

            onChange((hour * 60) + minute)
        }
    }

    const parse = (val: string) => {
        if (val === "") return null

        const time = val.split(":")
        if (time.length !== 2) return null
        return time
    }

    return <TextField
            disabled={ !dialogData?.auto_night_off }
            label={ helper }
            helperText={ "Podaj godzinę w formacie `HH:MM`" }
            value={ currentValue }
            onChange={ _onChange }
    />
}
