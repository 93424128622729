import { CharacteristicDefinition } from "../ble/Characteristic";
import { ServiceDefs } from "../ble/Device";
import { CharsDef, ServiceDef } from "../ble/Service";

const textDecoder = new TextDecoder();
const textEncoder = new TextEncoder();

const chRaw = (uuid: string) => ({
    uuid,
    decoder: async value => value,
    encoder: async value => value.buffer,
} as CharacteristicDefinition);
const chText = (uuid: string) => ({
    uuid, 
    decoder: async value => textDecoder.decode(value.buffer),
    encoder: async value => textEncoder.encode(value),
} as CharacteristicDefinition<string>);
const chTime = (uuid: string) => ({
    uuid,
    decoder: async value => {
        const view = new DataView(value.buffer);
        const epoch_time = view.getUint32(0, true) + (2 ** 32) * view.getUint32(4, true);
        const offset = (new Date()).getTimezoneOffset();
        return new Date((epoch_time + offset * 60) * 1000);
    },
    encoder: async value => {
        const offset = (new Date()).getTimezoneOffset();
        const num = Math.floor(((+value) / 1000) - (offset * 60));
        const buf = new Uint8Array(8);
        const view = new DataView(buf);
        view.setUint32(0, (num & 0xFFFFFFFF), true);
        view.setUint32(4, ((num / (2 ** 32)) & 0xFFFFFFFF), true);
        return buf;
    }
} as CharacteristicDefinition<Date>);
export interface DrsmKey {
    id: number,
    password: string,
}
export interface SignatureValue {
    nonce: DataView,
    key: DrsmKey,
};
const chSignature = (uuid: string) => ({
    uuid,
    decoder: async value => value,
    encoder: async ({nonce, key: { id, password }}) => {
        const key = await window.crypto.subtle.importKey(
            'raw', new TextEncoder().encode(password),
            {
                name: 'HMAC', hash: { name: 'SHA-256' }
            },
            false,
            ["sign", "verify"]
        );
        const signature = new DataView(await window.crypto.subtle.sign("HMAC", key, nonce));

        const value = new Uint8Array(33);
        value[0] = 0xD0 | (id & 0x0F);
        for (let i = 0; i < 32; i++)
            value[i + 1] = signature.getUint8(31 - i);

        return value;
    }
} as CharacteristicDefinition<DataView, SignatureValue>);
export interface PermissionsValue {
    config_zone: boolean,
    config_radio: boolean,
    upgrade_firmware: boolean,
    read_status: boolean,
    read_log: boolean,
    config_low_level: boolean,
    test_modes: boolean,
};
const chPermissions = (uuid: string) => ({
    uuid,
    encoder: async value => value.buffer,
    decoder: async view => {
        const data = new DataView(view.buffer);
        const value = data.getUint16(0, true);
        return {
            config_zone: (value & (1 << 0)) !== 0,
            config_radio: (value & (1 << 1)) !== 0,
            upgrade_firmware: (value & (1 << 2)) !== 0,
            read_status: (value & (1 << 3)) !== 0,
            read_log: (value & (1 << 4)) !== 0,
            config_low_level: (value & (1 << 5)) !== 0,
            test_modes: (value & (1 << 6)) !== 0,
        } as PermissionsValue;
    }
} as CharacteristicDefinition<PermissionsValue, DataView>);
export type PlogOpcode = 'plog_locked' | 'plog_unlocked' | 'plog_ram_size' | 'plog_nvm_size' | 'plog_ram_read' | 'plog_nvm_read';
export type PlogCommand = [PlogOpcode] | [PlogOpcode, number];
export interface PlogEntry {
    id: number,
    code: number,
    args: [number, number],
    timestamp: Date,
    rawValue: string,
};
const chReadLog = (uuid: string) => ({
    uuid,
    decoder: async (value) => {
        const view = new DataView(value.buffer);

        if (view.byteLength === 4) {
            return view.getUint32(0, true);
        }
        else if (view.byteLength === 15) {
            const id = view.getUint32(0, true);
            const timestamp = view.getUint32(4, true) + (2 ** 32) * view.getUint32(8, true);
            const code = view.getUint8(12);
            const args = [
                view.getUint8(13),
                view.getUint8(14),
                // view.getUint8(15),
            ];
            const offset = (new Date()).getTimezoneOffset();
            let rawValue = "";
            for(let i = 0; i < view.byteLength; i++)
                rawValue += ("00" + view.getUint8(i).toString(16)).slice(-2);

            return {
                id, code, args,
                timestamp: new Date((timestamp + offset * 60) * 1000),
                rawValue
            }
        }
    },
    encoder: async([opcode, arg]) => {
        let buffer = new ArrayBuffer(1);
        let view = new DataView(buffer);

        switch (opcode) {
            case 'plog_locked':
                if (arg !== undefined)
                    throw new Error(`mode ${opcode} does not support arguments!`);

                view.setUint8(0, 0);
                break;
            case 'plog_unlocked':
                if (arg !== undefined)
                    throw new Error(`mode ${opcode} does not support arguments!`);

                view.setUint8(0, 1);
                break;
            case 'plog_ram_size':
                if (arg !== undefined)
                    throw new Error(`mode ${opcode} does not support arguments!`);

                view.setUint8(0, 0x11);
                break;
            case 'plog_nvm_size':
                if (arg !== undefined)
                    throw new Error(`mode ${opcode} does not support arguments!`);

                view.setUint8(0, 0x10);
                break;
            case 'plog_ram_read':
                if (typeof arg === 'number') {
                    buffer = new ArrayBuffer(5);
                    view = new DataView(buffer);

                    view.setUint32(1, arg, true);
                }
                else if (arg !== null) {
                    throw new Error(`mode ${opcode} can take only integer as argument`);
                }

                view.setUint8(0, 0x21);
                break;
            case 'plog_nvm_read':
                if (typeof arg === 'number') {
                    buffer = new ArrayBuffer(5);
                    view = new DataView(buffer);

                    view.setUint32(1, arg, true);
                }
                else if (arg !== null) {
                    throw new Error(`mode ${opcode} can take only integer as argument`);
                }

                view.setUint8(0, 0x20);
                break;
            default:
                throw new Error(`mode ${opcode} is not valid!`);
        }
        return buffer;
    },
} as CharacteristicDefinition<PlogEntry | number, PlogCommand>)

const charsStatus = {
    hwverMb: chText("4eb6c12c-4906-0000-0002-e3d8e3f758ff"),
    hwverFp: chText("4eb6c12c-4906-0000-0003-e3d8e3f758ff"),
    fwverMb: chText("4eb6c12c-4906-0000-0004-e3d8e3f758ff"),
    bootverMb: chText("4eb6c12c-4906-0000-0005-e3d8e3f758ff"),
    fwverFp: chText("4eb6c12c-4906-0000-0006-e3d8e3f758ff"),
    serial: chText("4eb6c12c-4906-0000-0007-e3d8e3f758ff"),
    productionDate: chText("4eb6c12c-4906-0000-0008-e3d8e3f758ff"),
    mbUid: chRaw("4eb6c12c-4906-0000-0009-e3d8e3f758ff"),
    leds: chRaw("4eb6c12c-4906-0000-000a-e3d8e3f758ff"),
    battery: chRaw("4eb6c12c-4906-0000-000b-e3d8e3f758ff"),
    rssi: chRaw("4eb6c12c-4906-0000-000c-e3d8e3f758ff"),
    currentDatetime: chTime("4eb6c12c-4906-0000-000d-e3d8e3f758ff"),
    lastDatetime: chTime("4eb6c12c-4906-0000-000e-e3d8e3f758ff"),
    syncState: chRaw("4eb6c12c-4906-0000-000f-e3d8e3f758ff"),
    outputs: chRaw("4eb6c12c-4906-0000-0010-e3d8e3f758ff"),
    log: chText("4eb6c12c-4906-0000-0011-e3d8e3f758ff"),
    debug: chRaw("4eb6c12c-4906-0000-0012-e3d8e3f758ff"),
    coreTemperature: chRaw("4eb6c12c-4906-0000-0013-e3d8e3f758ff"),
};
export type StatusHwverMbCharacteristic = CharsDef<typeof charsStatus>['hwverMb'];
export type StatusHwverFpCharacteristic = CharsDef<typeof charsStatus>['hwverFp'];
export type StatusFwverMbCharacteristic = CharsDef<typeof charsStatus>['fwverMb'];
export type StatusBootverMbCharacteristic = CharsDef<typeof charsStatus>['bootverMb'];
export type StatusFwverFbCharacteristic = CharsDef<typeof charsStatus>['fwverFp'];
export type StatusSerialCharacteristic = CharsDef<typeof charsStatus>['serial'];
export type StatusProductionDateCharacteristic = CharsDef<typeof charsStatus>['productionDate'];
export type StatusMbUidCharacteristic = CharsDef<typeof charsStatus>['mbUid'];
export type StatusLedsCharacteristic = CharsDef<typeof charsStatus>['leds'];
export type StatusBatteryCharacteristic = CharsDef<typeof charsStatus>['battery'];
export type StatusRssiCharacteristic = CharsDef<typeof charsStatus>['rssi'];
export type StatusCurrentDatetimeCharacteristic = CharsDef<typeof charsStatus>['currentDatetime'];
export type StatusLastDatetimeCharacteristic = CharsDef<typeof charsStatus>['lastDatetime'];
export type StatusSyncStateCharacteristic = CharsDef<typeof charsStatus>['syncState'];
export type StatusOutputsCharacteristic = CharsDef<typeof charsStatus>['outputs'];
export type StatusLogCharacteristic = CharsDef<typeof charsStatus>['log'];
export type StatusDebugCharacteristic = CharsDef<typeof charsStatus>['debug'];
export type StatusCoreTemperatureCharacteristic = CharsDef<typeof charsStatus>['coreTemperature'];

const serviceStatus = {
    uuid: '4eb6c12c-4906-0000-0001-e3d8e3f758ff',
    characteristics: charsStatus as CharsDef<typeof charsStatus>,
};
export type StatusService = typeof serviceStatus;

const charsAuth = {
    nonce: chRaw("4eb6c12c-4906-0000-1002-e3d8e3f758ff"),
    signature: chSignature("4eb6c12c-4906-0000-1003-e3d8e3f758ff"),
    permissions: chPermissions("4eb6c12c-4906-0000-1004-e3d8e3f758ff"),
};
export type AuthNonceCharacteristic = CharsDef<typeof charsAuth>['nonce'];
export type AuthSignatureCharacteristic = CharsDef<typeof charsAuth>['signature'];
export type AuthPermissionsCharacteristic = CharsDef<typeof charsAuth>['permissions'];

const serviceAuth = {
    uuid: "4eb6c12c-4906-0000-1001-e3d8e3f758ff",
    characteristics: charsAuth as CharsDef<typeof charsAuth>,
};
export type AuthService = typeof serviceAuth;

const charsAction = {
    readLog: chReadLog("4eb6c12c-4906-0000-3006-e3d8e3f758ff"),
};
export type ActionReadLogCharacteristic = CharsDef<typeof charsAction>['readLog'];

const serviceAction = {
    uuid: "4eb6c12c-4906-0000-3001-e3d8e3f758ff",
    characteristics: charsAction as CharsDef<typeof charsAction>,
}
export type ActionService = typeof serviceAction;

const srvs = {
    status: serviceStatus as ServiceDef<typeof serviceStatus>,
    auth: serviceAuth as ServiceDef<typeof serviceAuth>,
    action: serviceAction as ServiceDef<typeof serviceAction>,
};
// export const test2 : CharsDef<
// export const test1 : ServiceDefBase<typeof srvs['status']['characteristics']> = srvs.status;
// export const test2 : ServiceDef<typeof test1> = srvs.status;
export const DrsmServices : ServiceDefs<typeof srvs> = srvs;

function checkType<T>(value: ServiceDefs<T>) {}
checkType(DrsmServices);