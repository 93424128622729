// @flow
import * as React                                from 'react'
import { useEffect, useState }                   from 'react'
import { Button, ButtonGroup, Card, Typography } from "@material-ui/core"
import { makeStyles }                            from "@material-ui/core/styles"
import { useHistory, useParams }                 from 'react-router-dom'
import { AppError }                              from "../models/appError"
import { DataBase }                              from "../db/db"

type Props = {};

const useClasses = makeStyles({
    card       : {
        padding  : "2em",
        marginTop: "1em"
    },
    actionCard: {
        padding: "1em",
        marginTop: "1em"
    },
    buttonGroup: {
        width: "100%"
    },
    button: {
        padding: "0 auto"
    }
})


export function AppErrorDetail(_: Props) {
    const history = useHistory()
    const styles = useClasses()
    const [ error, setError ] = useState<AppError>()
    let { id } = useParams<{ id: string }>()

    useEffect(() => {
        const getItem = async () => {
            setError(await DataBase.userErrors.get(id))
        }

        getItem().catch(e => console.error(e));
    }, [id]);

    return (
            <div>
                <Card elevation={12} className={styles.card}>
                    <Typography color={"primary"}>
                        Zgłoszenie błędu {error?.timestamp.nanoseconds ?? "ładowanie"}
                    </Typography>
                </Card>

                <Card elevation={12} className={styles.card}>
                    <Typography color={"primary"}>
                        Wiadomość błędu
                    </Typography>
                    <p>
                        {error?.message ?? "Brak wiadomości"}
                    </p>
                </Card>

                <Card elevation={12} className={styles.card}>
                    <Typography color={"primary"}>
                        Logi
                    </Typography>
                    <p>
                        {error?.logs.split("\n").map(v => <p>{v}</p>) ?? ""}
                    </p>
                </Card>

                <Card className={styles.actionCard}>
                    <ButtonGroup className={styles.buttonGroup}>
                        <Button className={styles.button} onClick={async () => {
                            await DataBase.userErrors.update(id, {resolved: true})
                            history.goBack()
                        }} color={"primary"}>Oznacz jako rozwiązane</Button>
                        <Button className={styles.button} onClick={() => history.goBack()}>Wróć</Button>
                        <Button className={styles.button} onClick={async () => {
                            await DataBase.userErrors.delete(id)
                            history.goBack()
                        }} color={"secondary"}>Usuń</Button>
                    </ButtonGroup>
                </Card>
            </div>
    )
}
