import { Button, makeStyles, Paper, Popper, Typography } from '@material-ui/core';
import { DataGrid, GridColDef, GridOverlay, GridCellParams, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { JSXElementConstructor, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Device } from '../ble/Device';
import { ActionReadLogCharacteristic, ActionService, DrsmServices, PermissionsValue, PlogEntry } from '../constants/bleDefs';

export interface DrsmLogProps
{
    device: Device<typeof DrsmServices>;
    permissions: PermissionsValue;
    onDisconnectClick?: () => void;
    serial: string;
}

const relay_source_map = ['Nieznany', 'Wymuszenie lokalne', 'Wymuszenie przez radio', 'Radio', 'Nadzór autonomiczny', 'Tryb autonomiczny', 'Brak wartości'];
const relay_reason_map = ['Uruchomienie urządzenia', 'Wymuszenie lokalne', 'Zmiana trybu pracy', 'Sterowanie autonomiczne', 'Nadzór autonomiczny', 'Sterowanie radiowe', 'Wymuszenie radiowe'];

const plog_events_map : {[id: number]: [string, (args: [number,number]) => string]} = {
    0: ['Włączenie urządzenia', ([b1, b2]) => ''],
    1: ['Zanik zasilania', ([b1, b2]) => ''],
    2: ['Załączenie przekaźnika', ([b1, b2]) => `Wywołane przez ${relay_source_map[b1]}`],
    3: ['Wyłączenie przekaźnika', ([b1, b2]) => `Wywołane przez ${relay_source_map[b1]}`],
    4: ['Kalkulacja stanu przekaźnika', ([b1, b2]) => `Wywołana przez ${relay_reason_map[(b1 & 0xF0) >> 4]}. Ustalony stan ${b2 ? 'włączony' : 'wyłączony'} na podstawie ${relay_source_map[b1 & 0x0F]}`],
    5: ['Utracony sygnał', ([b1, b2]) => ''],
    6: ['Odzyskany sygnał sygnał', ([b1, b2]) => `Poziom sygnału ${b1 | (b2 << 8)} mV`],
    7: ['Żądanie resetu', ([b1, b2]) => ''],
    8: ['Błąd watchdog', ([b1, b2]) => `Status: ${b1}`],
    9: ['Połączenie bluetooth', ([b1, b2]) => ''],
    10: ['Rozłączenie bluetooth', ([b1, b2]) => ''],
    11: ['Logowanie operatora', ([b1, b2]) => ''],
}

interface GridCellExpandProps {
    value: string;
    width: number;
  }
  
  function isOverflown(element: Element): boolean {
    const ret = (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
    console.log('overflown', element, ret);
    return ret;
  }

const GridCellExpand = memo(function GridCellExpand(
    props: GridCellExpandProps,
  ) {
    const { width, value } = props;
    const wrapper = useRef<HTMLDivElement | null>(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);
  
    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current!);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(wrapper.current);
      setShowFullCell(true);
    };
  
    const handleMouseLeave = () => {
      setShowFullCell(false);
    };
  
    useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }
  
      function handleKeyDown(nativeEvent: KeyboardEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          setShowFullCell(false);
        }
      }
  
      document.addEventListener('keydown', handleKeyDown);
  
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);
  
    return (
      <div
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          alignItems: 'center',
          lineHeight: '24px',
          position: 'relative',
          display: 'flex',
          maxWidth: '100%',
        }}
      >
        <div
          ref={cellDiv}
          style={{
            display: 'block',
            position: 'absolute',
            top: 0,
            width,
          }}
        />
        <div
          ref={cellValue}
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {value}
        </div>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </div>
    );
  });
  
  function renderCellExpand(params: GridCellParams) {
    return (
      <GridCellExpand value={(params.formattedValue as string) || ''} width={params.colDef.computedWidth} />
    );
  }
  

const NVM_ID_OFFSET = 10000;
const columns: GridColDef[] = [
    { 
        field: 'id',
        headerName: 'ID',
        valueGetter: ({ row: { id } }) => (typeof id === 'number') ? ((id >= NVM_ID_OFFSET) ? `NVM/${+id - NVM_ID_OFFSET}` : `RAM/${id}`) : id,
    },
    {
        field: 'timestamp',
        headerName: 'Data i czas',
        valueGetter: ({ row: { timestamp } }) => (timestamp instanceof Date) ? timestamp.toLocaleString() : timestamp,
        width: 200,
    },
    {
        field: 'code',
        headerName: 'Zdarzenie',
        valueGetter: ({ row: { code } }) => plog_events_map[code] ? plog_events_map[code][0] : `Nieznane ${code}`,
        renderCell: renderCellExpand,
        width: 300,
        // flex: 1,
    },
    {
        field: 'args',
        headerName: 'Szczegóły',
        valueGetter: ({ row: { code, args } }) => plog_events_map[code] ? plog_events_map[code][1](args) : `(${args[0]}, ${args[1]})`,
        renderCell: renderCellExpand,
        width: 700,
        // flex: 2,
    },
    {
        field: 'rawValue',
        headerName: 'Surowa wartość',
        hide: true   
    }
];

const useStyles = makeStyles(theme => ({
    grid: {
        flexGrow: 1,
        minHeight: 250,
    },
    errorOverlay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    toolbar: {
        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        }
    }
}));

export function DrsmLog({ serial, device, permissions, onDisconnectClick }: DrsmLogProps)
{
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<string>();
    const [ rows, setRows ] = useState<PlogEntry[]>([]);
    const classes = useStyles();

    const readLog = useMemo(() => async() => {
        try
        {
            setError(undefined);
            setLoading(true);

            const chReadLog = device.getService<ActionService>('action')?.getCharacteristic<ActionReadLogCharacteristic>('readLog');
            if (! chReadLog)
                throw new Error('Cannot get read log characteristic.');

            try
            {
                setRows([]);
                await chReadLog.write(['plog_locked']);
                await chReadLog.write(['plog_nvm_size']);
                const nvmSize = await chReadLog.read();
                if (typeof nvmSize !== 'number')
                    throw new Error('Nieoczekiwana odpowiedź na żądanie plog_nvm_size');

                await chReadLog.write(['plog_ram_size']);
                const ramSize = await chReadLog.read();
                if (typeof ramSize !== 'number')
                    throw new Error('Nieoczekiwana odpowiedź na żądanie plog_ram_size');
                
                await chReadLog.write(['plog_nvm_read', 0]);
                for (let i = 0; i < nvmSize; i++) {
                    const row = await chReadLog.read();
                    if (typeof row === 'number')
                        throw new Error('Nieoczekiwana odpowiedź na żądanie plog_nvm_read');
                    
                    row.id += NVM_ID_OFFSET;

                    setRows(current => [...current, row]);
                }
                
                await chReadLog.write(['plog_ram_read', 0]);
                for (let i = 0; i < ramSize; i++) {
                    const row = await chReadLog.read();
                    if (typeof row === 'number')
                        throw new Error('Nieoczekiwana odpowiedź na żądanie plog_ram_read');
                    
                    setRows(current => [row, ...current]);
                }

                setRows(current => [...current].sort((a, b) => +b.timestamp - +a.timestamp));
            }
            finally
            {
                await chReadLog.write(['plog_unlocked']);
            }
        }
        catch(e)
        {
            console.warn('Error reading log', e);
            if (e instanceof Error)
                setError(e.message);
            else
                setError(String(e));
        }
        finally
        {
            setLoading(false);
        }
    }, [device]);

    const toolbar = useMemo(() => () => (
        <GridToolbarContainer className={classes.toolbar}>
            { onDisconnectClick && <Button variant='outlined' color='secondary' onClick={onDisconnectClick}>Rozłącz</Button> }
            { permissions.read_log && <Button variant='contained' color='primary' disabled={loading} onClick={readLog}>Odczytaj z urządzenia</Button> }
            <GridToolbarExport csvOptions={{ 
              allColumns: true,
              utf8WithBom: true,
              fileName: `DRSM_${serial}`
            }} />
        </GridToolbarContainer>
    ), [serial, loading, readLog, onDisconnectClick, permissions, classes]);

    const errorComponent = useMemo(() => () => <GridOverlay className={classes.errorOverlay}><Typography variant='body1' color='error'>{error}</Typography></GridOverlay>, [error, classes]);
    const unsupportedLogComponent = useMemo(() => () => <Typography variant='body1' color='error'>Urządzenie nie wspiera logu zdarzeń</Typography>, []);
    const connectingToDeviceComponent = useMemo(() => () => <Typography variant='body1' color='textPrimary'>Trwa łączenie z urządzeniem</Typography>, []);

    let noRowsOverlay : JSXElementConstructor<any> | undefined = undefined;
    if (! permissions.config_zone)
        noRowsOverlay = connectingToDeviceComponent;
    else if (! permissions.read_log)
        noRowsOverlay = unsupportedLogComponent;
    else if (error)
        noRowsOverlay = errorComponent;

    if (! permissions.config_zone )
        return null;
    else if (! permissions.read_log)
        return (<Typography variant='body1' color='error'>Urządzenie nie wspiera logu zdarzeń</Typography>)
    else
    {
        return (
            <DataGrid 
                className={classes.grid}
                rows={error ? [] : rows} columns={columns} 
                loading={loading}
                components={{ 
                    Toolbar: toolbar,
                    NoRowsOverlay: noRowsOverlay,
                }} 
            />
        );
    }
}