import { SvgIcon }                from "@material-ui/core"
import AccountCircleIcon          from '@material-ui/icons/AccountCircle'
import RadioIcon                  from '@material-ui/icons/Radio'
import ExitToAppIcon              from '@material-ui/icons/ExitToApp'
import BugReportIcon              from '@material-ui/icons/BugReport'
import VpnKeyIcon                 from '@material-ui/icons/VpnKey'
import PublicIcon                 from '@material-ui/icons/Public'
import CreateIcon                 from '@material-ui/icons/Create'
import HistoryIcon                from '@material-ui/icons/History'
import { AuthProvider }           from "../providers/AuthProvider"
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation'

export interface DrawerItem {
    name: string
    path: string

    isLoginProtected?: boolean
    isDivider: boolean
    // @ts-ignore
    icon?: SvgIcon
    checkPerms?: (auth: AuthProvider) => boolean
}

const hasGod = (auth: AuthProvider) => !!auth.user?.perms.god
const canManagePerms = (auth: AuthProvider) => !!auth.user?.perms.manage_perms || !!auth.user?.perms.god
const canEditZones = (auth: AuthProvider) => !!auth.user?.perms.edit_zones || !!auth.user?.perms.god
const canConnectDrsm = (auth: AuthProvider) => (!!auth.user?.perms.connect_drsm && !!auth.user?.perms.manage_perms) || !!auth.user?.perms.god
const canReadDeviceLog = (auth: AuthProvider) => !!auth.user?.perms.read_device_log || !!auth.user?.perms.god

const divider: DrawerItem = { name: "", path: "", icon: undefined, isDivider: true, isLoginProtected: true }

export const drawerData: DrawerItem[] = [
    { 
        name: "Użytkownicy", 
        path: "/users", 
        icon: <AccountCircleIcon/>, 
        isDivider: false, 
        isLoginProtected: true,
        checkPerms: canManagePerms
    },
    {
        name            : "Urządzenia",
        path            : "/drsm",
        icon            : <RadioIcon/>,
        isDivider       : false,
        isLoginProtected: true,
        checkPerms      : canConnectDrsm
    },
    {
        name            : "Odczyt log DRSM",
        path            : "/read-drsm-log",
        icon            : <HistoryIcon />,
        isDivider       : false,
        isLoginProtected: true,
        checkPerms      : canReadDeviceLog
    },
    { 
        name: "Strefy", 
        path: "/zones",
        icon: <PublicIcon/>, 
        isDivider: false, 
        isLoginProtected: true,
        checkPerms: canEditZones
    },
    {
        name            : "AppBugs",
        path            : "/app-bugs",
        icon            : <BugReportIcon/>,
        isDivider       : false,
        isLoginProtected: true,
        checkPerms      : hasGod
    },
    {
        name            : "DrsmBugs",
        path            : "/drsm-errors",
        icon            : <BugReportIcon/>,
        isDivider       : false,
        isLoginProtected: true,
        checkPerms      : hasGod
    },
    { 
        name: "Firmy", 
        path: "/companies", 
        icon: <BugReportIcon/>, 
        isDivider: false, 
        isLoginProtected: true,
        checkPerms: hasGod
    },
    {
        name            : "Przekazanie urządzenia",
        path            : "/drsm-transfers",
        icon            : <TransferWithinAStationIcon/>,
        isDivider       : false,
        isLoginProtected: true,
        checkPerms      : hasGod
    },
    divider,
    { name: "Zmień hasło", path: "/change-password", icon: <VpnKeyIcon/>, isDivider: false, isLoginProtected: true },
    { name: "Wyloguj", path: "/logout", icon: <ExitToAppIcon/>, isDivider: false, isLoginProtected: true },
    { name: "Login", path: "/login", icon: <VpnKeyIcon/>, isDivider: false, isLoginProtected: false },
    { name: "Rejestracja", path: "/register", icon: <CreateIcon/>, isDivider: false, isLoginProtected: false },
]
