import React from "react"

export interface AuthProvider {
    isLoggedIn: boolean,
    isLoading: boolean,
    user?: {
        email: string,
        first_name: string,
        last_name: string,
        company: string,
        contractor: string,
        perms: {
            god: boolean,
            manage_perms: boolean,
            manage_company: boolean,
            use_web: boolean,
            connect_drsm: boolean,
            upload_firmware: boolean,
            device_settings: boolean,
            read_device_log: boolean,
            edit_zones: boolean,
        }
    } | null
}

export type AuthContext = {
    auth: AuthProvider,
    setAuth: React.Dispatch<React.SetStateAction<AuthProvider>>
}

// @ts-ignore
export const AuthProviderContext = React.createContext<AuthContext>(null)
